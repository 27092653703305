const TEMPLATE_STORAGE_KEY = 'description_templates';

export function loadTemplates(): { [key: string]: string } {
  try {
    const stored = localStorage.getItem(TEMPLATE_STORAGE_KEY);
    return stored ? JSON.parse(stored) : {};
  } catch (error) {
    console.error('Failed to load templates:', error);
    return {};
  }
}

export function saveTemplates(templates: { [key: string]: string }): void {
  try {
    localStorage.setItem(TEMPLATE_STORAGE_KEY, JSON.stringify(templates));
  } catch (error) {
    console.error('Failed to save templates:', error);
  }
}