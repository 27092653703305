import React, { useState, useCallback, useRef } from 'react';
import { Upload, Image as ImageIcon, AlertCircle } from 'lucide-react';
import { ImagePopup } from './folders/ImagePopup';
import { processImageFiles, validateImageCount } from '../utils/imageUtils';
import { MAX_IMAGES } from '../utils/constants';
import { CameraCapture } from './camera/CameraCapture';
import { DraggableProductImages } from './images/DraggableProductImages';

interface ImageUploadProps {
  images: string[];
  onImagesChange: (images: string[]) => void;
  title?: string;
}

export function ImageUpload({ images, onImagesChange, title }: ImageUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [previewIndex, setPreviewIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const dragCounter = useRef(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const showError = (message: string) => {
    setError(message);
    setTimeout(() => setError(null), 3000);
  };

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;

    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );

    if (files.length === 0) {
      showError('画像ファイルをドロップしてください');
      return;
    }

    const validation = validateImageCount(images.length, files.length);
    if (!validation.valid && validation.error) {
      showError(validation.error);
      return;
    }

    try {
      const newImages = await processImageFiles(e.dataTransfer.files, images);
      onImagesChange(newImages);
    } catch (error) {
      showError('画像の処理中にエラーが発生しました');
    }
  }, [images, onImagesChange]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const validation = validateImageCount(images.length, files.length);
    if (!validation.valid && validation.error) {
      showError(validation.error);
      return;
    }

    try {
      const newImages = await processImageFiles(files, images);
      onImagesChange(newImages);
    } catch (error) {
      showError('画像の処理中にエラーが発生しました');
    }
    e.target.value = '';
  };

  const handleImagesCapture = (capturedImages: string[]) => {
    const validation = validateImageCount(images.length, capturedImages.length);
    if (!validation.valid && validation.error) {
      showError(validation.error);
      return;
    }

    onImagesChange([...images, ...capturedImages].slice(0, MAX_IMAGES));
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <ImageIcon className="w-5 h-5 text-gray-600" />
          <label className="block text-sm font-medium text-gray-700">商品画像</label>
        </div>
        <span className={`text-sm ${images.length >= MAX_IMAGES ? 'text-red-500' : 'text-gray-500'}`}>
          {images.length}/{MAX_IMAGES}枚
        </span>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-2 rounded">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}

      <div
        ref={containerRef}
        className={`relative border-2 ${
          isDragging ? 'border-blue-500 bg-blue-50' : 'border-dashed border-gray-300'
        } rounded-lg transition-colors duration-200`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {images.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-64">
            <label className="w-full cursor-pointer">
              <div className="flex flex-col items-center justify-center h-48 hover:bg-gray-50 transition-colors duration-200">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                />
                <Upload className="h-12 w-12 text-gray-400 mb-3" />
                <div className="text-center">
                  <p className="text-sm font-medium text-gray-900">クリックまたはドラッグで追加</p>
                  <p className="text-xs text-gray-500 mt-2">
                    最大{MAX_IMAGES}枚まで・1枚10MBまで
                  </p>
                </div>
              </div>
            </label>
            <div className="mt-4">
              <CameraCapture
                onImagesCapture={handleImagesCapture}
                currentImages={images}
              />
            </div>
          </div>
        ) : (
          <div className="p-4">
            <DraggableProductImages
              images={images}
              onImagesChange={onImagesChange}
              onPreview={setPreviewIndex}
            />

            {images.length < MAX_IMAGES && (
              <div className="mt-4 flex gap-4 justify-center">
                <label className="block cursor-pointer">
                  <div className="border-2 border-dashed border-gray-300 rounded-lg aspect-square flex flex-col items-center justify-center hover:bg-gray-50 transition-colors duration-200 p-4">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                      ref={fileInputRef}
                    />
                    <Upload className="h-8 w-8 text-gray-400 mb-2" />
                    <span className="text-sm text-gray-600">画像を追加</span>
                  </div>
                </label>
                <div className="flex items-center justify-center">
                  <CameraCapture
                    onImagesCapture={handleImagesCapture}
                    currentImages={images}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {isDragging && (
          <div className="absolute inset-0 bg-blue-50 bg-opacity-90 flex items-center justify-center rounded-lg z-50">
            <div className="text-center">
              <Upload className="mx-auto h-16 w-16 text-blue-500 mb-3" />
              <span className="text-lg font-medium text-blue-600">
                ここにドロップ
              </span>
            </div>
          </div>
        )}
      </div>

      {previewIndex !== null && (
        <ImagePopup
          images={images}
          currentIndex={previewIndex}
          title={title}
          onClose={() => setPreviewIndex(null)}
          onPrevious={() => setPreviewIndex(prev => Math.max(0, prev - 1))}
          onNext={() => setPreviewIndex(prev => Math.min(images.length - 1, prev + 1))}
        />
      )}
    </div>
  );
}