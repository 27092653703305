export const PACKAGE_SIZE_VALUES = {
  '60': 1,
  '80': 2,
  '100': 3,
  '120': 4,
  '140': 5,
  '160': 6,
  '180': 7,
  '200': 8
} as const;

export const PACKAGE_SIZE_LABELS = {
  1: '～60cm',
  2: '～80cm',
  3: '～100cm',
  4: '～120cm',
  5: '～140cm',
  6: '～160cm',
  7: '～180cm',
  8: '～200cm'
} as const;

// 文字列のサイズを数値に変換 (例: "160" → 6)
export function getSizeValue(size: string): number {
  const numericSize = parseInt(size.replace(/[^0-9]/g, ''));
  if (isNaN(numericSize)) return 0;

  // 各範囲の最大値と比較して適切な値を返す
  if (numericSize <= 60) return 1;
  if (numericSize <= 80) return 2;
  if (numericSize <= 100) return 3;
  if (numericSize <= 120) return 4;
  if (numericSize <= 140) return 5;
  if (numericSize <= 160) return 6;
  if (numericSize <= 180) return 7;
  return 8;
}

// 数値を文字列形式に変換 (例: 6 → "～160cm")
export function getSizeLabel(value: number): string {
  return PACKAGE_SIZE_LABELS[value as keyof typeof PACKAGE_SIZE_LABELS] || '';
}