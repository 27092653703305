import React from 'react';
import { Plus } from 'lucide-react';
import { YamatoShippingMethods } from './YamatoShippingMethods';
import { JapanPostShippingMethods } from './JapanPostShippingMethods';
import { CustomShippingMethodList } from './CustomShippingMethodList';
import type { Product } from '../../types';

interface ShippingMethodsGroupProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onCustomMethodAdd: () => void;
  onCustomMethodUpdate: (index: number, method: any) => void;
  onCustomMethodRemove: (index: number) => void;
  showPrices: boolean;
}

export const ShippingMethodsGroup: React.FC<ShippingMethodsGroupProps> = ({
  product,
  onChange,
  onCustomMethodAdd,
  onCustomMethodUpdate,
  onCustomMethodRemove,
  showPrices
}) => {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">配送方法</h4>
      <div className="space-y-6">
        {/* Standard shipping methods */}
        {!product.shippingCOD && (
          <>
            <YamatoShippingMethods product={product} onChange={onChange} />
            <JapanPostShippingMethods product={product} onChange={onChange} />
          </>
        )}

        {/* Custom shipping methods - show for both normal and COD shipping */}
        <CustomShippingMethodList
          product={product}
          showPrices={showPrices}
          onCustomMethodAdd={onCustomMethodAdd}
          onCustomMethodUpdate={onCustomMethodUpdate}
          onCustomMethodRemove={onCustomMethodRemove}
        />
      </div>
    </div>
  );
};