import React, { useState } from 'react';
import { Save, ChevronUp } from 'lucide-react';
import { ProductCompactView } from './ProductCompactView';
import { DetailSettings } from './DetailSettings';
import { ListingInfo } from './ListingInfo';
import { ImageUpload } from './ImageUpload';
import { TitleInput } from './TitleInput';
import { SaveTemplateDialog } from './templates/SaveTemplateDialog';
import { createTitleWithItemNumbers } from '../utils/titleManager';
import { calculateCharacterCount } from '../utils/characterCount';
import type { Product } from '../types';

interface ProductFormProps {
  product: Product;
  onUpdate: (product: Product) => void;
  onDelete: (productId: number) => void;
  isSelected?: boolean;
  onSelect?: (selected: boolean, event?: React.MouseEvent) => void;
  isExpanded: boolean;
  onExpand: () => void;
}

export function ProductForm({
  product,
  onUpdate,
  onDelete,
  isSelected = false,
  onSelect,
  isExpanded,
  onExpand
}: ProductFormProps) {
  const [draftProduct, setDraftProduct] = useState(product);
  const [isDirty, setIsDirty] = useState(false);
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [useDescriptionTemplate, setUseDescriptionTemplate] = useState(false);

  React.useEffect(() => {
    setDraftProduct(product);
    setIsDirty(false);
  }, [product]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
    setDraftProduct(prev => ({
      ...prev,
      [name]: newValue
    }));
    setIsDirty(true);
  };

  const handleTitleChange = (value: string) => {
    setDraftProduct(prev => ({
      ...prev,
      title: value
    }));
    setIsDirty(true);
  };

  const handleListingTypeChange = (type: 'auction' | 'fixed') => {
    setDraftProduct(prev => ({
      ...prev,
      listingType: type,
      buyItNowPrice: type === 'fixed' ? undefined : prev.buyItNowPrice
    }));
    setIsDirty(true);
  };

  const handleImagesChange = (images: string[]) => {
    setDraftProduct(prev => ({
      ...prev,
      images
    }));
    setIsDirty(true);
    
    onUpdate({
      ...draftProduct,
      images
    });
  };

  const handleSave = () => {
    const charCount = calculateCharacterCount(draftProduct);
    if (!charCount.isValid) {
      return;
    }

    const updatedProduct = {
      ...draftProduct,
      title: createTitleWithItemNumbers(draftProduct)
    };
    onUpdate(updatedProduct);
    onExpand();
    setIsDirty(false);
  };

  const handleDelete = () => {
    onDelete(product.id);
  };

  const handleTemplateSaved = () => {
    setShowSaveTemplate(false);
  };

  const canSave = isDirty && calculateCharacterCount(draftProduct).isValid;

  if (!isExpanded) {
    return (
      <ProductCompactView
        product={draftProduct}
        onExpand={onExpand}
        onDelete={handleDelete}
        isSelected={isSelected}
        onSelect={onSelect}
        onUpdate={(updatedProduct) => {
          setDraftProduct(updatedProduct);
          onUpdate(updatedProduct);
        }}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4 sm:p-6 space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={onExpand}
            className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
          >
            <ChevronUp className="w-5 h-5" />
            <span>折りたたむ</span>
          </button>
          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowSaveTemplate(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-green-600 bg-green-100 rounded-md hover:bg-green-200"
            >
              <Save className="w-4 h-4" />
              <span>テンプレートとして保存</span>
            </button>
            {isDirty && (
              <button
                onClick={handleSave}
                disabled={!canSave}
                className={`flex items-center gap-2 px-4 py-2 text-sm font-medium text-white rounded-md ${
                  canSave
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                <Save className="w-4 h-4" />
                <span>保存</span>
              </button>
            )}
          </div>
        </div>

        <div className="space-y-6">
          <ImageUpload
            images={draftProduct.images}
            onImagesChange={handleImagesChange}
            title={draftProduct.title}
          />
          
          <div className="space-y-6">
            <TitleInput
              value={draftProduct.title}
              onChange={handleTitleChange}
              product={draftProduct}
            />

            <ListingInfo
              product={draftProduct}
              onChange={handleChange}
              onListingTypeChange={handleListingTypeChange}
            />
          </div>
        </div>

        <DetailSettings
          product={draftProduct}
          onChange={handleChange}
          useDescriptionTemplate={useDescriptionTemplate}
          onDescriptionTemplateChange={(e) => setUseDescriptionTemplate(e.target.checked)}
        />
      </div>

      {showSaveTemplate && (
        <SaveTemplateDialog
          product={draftProduct}
          onClose={() => setShowSaveTemplate(false)}
          onSaved={handleTemplateSaved}
        />
      )}
    </div>
  );
}