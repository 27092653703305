import React, { useState } from 'react';
import { Settings, Tag, Gavel, Hash, FolderOpen, Truck, Clock, MapPin } from 'lucide-react';
import type { BulkUpdates } from '../types/bulk';
import { prefectures } from '../utils/prefectures';
import { BulkTitleField } from './bulk/BulkTitleField';

interface BulkActionBarProps {
  onUpdate: (updates: BulkUpdates) => void;
  selectedCount: number;
}

export function BulkActionBar({ onUpdate, selectedCount }: BulkActionBarProps) {
  const [startPrice, setStartPrice] = useState<string>('');
  const [buyItNowPrice, setBuyItNowPrice] = useState<string>('');
  const [listingType, setListingType] = useState<'auction' | 'fixed' | ''>('');
  const [condition, setCondition] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [shippingResponsibility, setShippingResponsibility] = useState<'buyer' | 'seller' | ''>('');
  const [shippingTime, setShippingTime] = useState<'1-2' | '2-3' | '3-7' | ''>('');
  const [shippingLocation, setShippingLocation] = useState<string>('');
  const [itemNumber, setItemNumber] = useState('');
  const [itemNumber2, setItemNumber2] = useState('');
  const [itemNumberPrefix, setItemNumberPrefix] = useState(false);
  const [itemNumberSuffix, setItemNumberSuffix] = useState(false);
  const [itemNumberSerial, setItemNumberSerial] = useState(false);
  const [memoPrefix, setMemoPrefix] = useState(false);
  const [memoSuffix, setMemoSuffix] = useState(false);
  const [memoSerial, setMemoSerial] = useState(false);

  const handleListingTypeApply = () => {
    if (listingType) {
      onUpdate({ listingType });
      setListingType('');
    }
  };

  const handlePriceApply = () => {
    const updates: BulkUpdates = {};
    if (startPrice) {
      updates.startPrice = Number(startPrice);
    }
    if (buyItNowPrice) {
      updates.buyItNowPrice = Number(buyItNowPrice);
    }
    if (Object.keys(updates).length > 0) {
      onUpdate(updates);
      setStartPrice('');
      setBuyItNowPrice('');
    }
  };

  const handleConditionApply = () => {
    if (condition) {
      onUpdate({ condition });
      setCondition('');
    }
  };

  const handleCategoryApply = () => {
    if (category) {
      onUpdate({ category });
      setCategory('');
    }
  };

  const handleShippingResponsibilityApply = () => {
    if (shippingResponsibility) {
      onUpdate({ shippingResponsibility });
      setShippingResponsibility('');
    }
  };

  const handleShippingTimeApply = () => {
    if (shippingTime) {
      onUpdate({ shippingTime });
      setShippingTime('');
    }
  };

  const handleShippingLocationApply = () => {
    if (shippingLocation) {
      onUpdate({ shippingLocation });
      setShippingLocation('');
    }
  };

  const handleItemNumberApply = () => {
    const updates: BulkUpdates = {};
    
    if (itemNumber) updates.itemNumber = itemNumber;
    if (itemNumber2) updates.itemNumber2 = itemNumber2;
    if (itemNumberPrefix) updates.itemNumberPrefix = itemNumberPrefix;
    if (itemNumberSuffix) updates.itemNumberSuffix = itemNumberSuffix;
    if (itemNumberSerial) updates.itemNumberSerial = itemNumberSerial;
    if (memoPrefix) updates.memoPrefix = memoPrefix;
    if (memoSuffix) updates.memoSuffix = memoSuffix;
    if (memoSerial) updates.memoSerial = memoSerial;

    if (Object.keys(updates).length > 0) {
      onUpdate(updates);
      setItemNumber('');
      setItemNumber2('');
      setItemNumberPrefix(false);
      setItemNumberSuffix(false);
      setItemNumberSerial(false);
      setMemoPrefix(false);
      setMemoSuffix(false);
      setMemoSerial(false);
    }
  };

  return (
    <div className="p-4 space-y-6">
      <div className="text-sm text-gray-600 mb-4">
        選択中のアイテム: {selectedCount}件
      </div>

      {/* Title Field */}
      <BulkTitleField onUpdate={onUpdate} />

      {/* Listing Type */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Tag className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">出品形式を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={listingType}
            onChange={(e) => setListingType(e.target.value as 'auction' | 'fixed')}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="auction">オークション</option>
            <option value="fixed">定額</option>
          </select>
          <button
            onClick={handleListingTypeApply}
            disabled={!listingType}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              listingType
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Category */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <FolderOpen className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">カテゴリを変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="books">本・雑誌</option>
            <option value="music">CD・音楽</option>
            <option value="games">ゲーム</option>
            <option value="toys">おもちゃ・ホビー</option>
            <option value="fashion">ファッション</option>
            <option value="electronics">家電・スマホ・カメラ</option>
            <option value="sports">スポーツ・レジャー</option>
            <option value="handmade">ハンドメイド</option>
            <option value="other">その他</option>
          </select>
          <button
            onClick={handleCategoryApply}
            disabled={!category}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              category
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Shipping Responsibility */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Truck className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">送料負担を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingResponsibility}
            onChange={(e) => setShippingResponsibility(e.target.value as 'buyer' | 'seller' | '')}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="buyer">落札者</option>
            <option value="seller">出品者</option>
          </select>
          <button
            onClick={handleShippingResponsibilityApply}
            disabled={!shippingResponsibility}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingResponsibility
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Shipping Time */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Clock className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">発送までの日数を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingTime}
            onChange={(e) => setShippingTime(e.target.value as '1-2' | '2-3' | '3-7' | '')}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="1-2">1～2日</option>
            <option value="2-3">2～3日</option>
            <option value="3-7">3～7日</option>
          </select>
          <button
            onClick={handleShippingTimeApply}
            disabled={!shippingTime}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingTime
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Shipping Location */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <MapPin className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">発送元の地域を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingLocation}
            onChange={(e) => setShippingLocation(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            {prefectures.map(prefecture => (
              <option key={prefecture} value={prefecture}>
                {prefecture}
              </option>
            ))}
          </select>
          <button
            onClick={handleShippingLocationApply}
            disabled={!shippingLocation}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingLocation
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Prices */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Gavel className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">価格を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <input
              type="number"
              value={startPrice}
              onChange={(e) => setStartPrice(e.target.value)}
              placeholder="開始価格"
              className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
            />
            <span className="text-sm text-gray-500">円</span>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="number"
              value={buyItNowPrice}
              onChange={(e) => setBuyItNowPrice(e.target.value)}
              placeholder="即決価格"
              className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
            />
            <span className="text-sm text-gray-500">円</span>
          </div>
          <button
            onClick={handlePriceApply}
            disabled={!startPrice && !buyItNowPrice}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              startPrice || buyItNowPrice
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Item Numbers */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Hash className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">管理番号を変更</span>
        </div>
        <div className="space-y-3">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={itemNumber}
                onChange={(e) => setItemNumber(e.target.value)}
                placeholder="管理番号1"
                className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
              />
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={itemNumberPrefix}
                    onChange={(e) => {
                      setItemNumberPrefix(e.target.checked);
                      if (e.target.checked) setItemNumberSuffix(false);
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">文頭</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={itemNumberSuffix}
                    onChange={(e) => {
                      setItemNumberSuffix(e.target.checked);
                      if (e.target.checked) setItemNumberPrefix(false);
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">文尾</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={itemNumberSerial}
                    onChange={(e) => setItemNumberSerial(e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">連番</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={itemNumber2}
                onChange={(e) => setItemNumber2(e.target.value)}
                placeholder="管理番号2"
                className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
              />
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={memoPrefix}
                    onChange={(e) => {
                      setMemoPrefix(e.target.checked);
                      if (e.target.checked) setMemoSuffix(false);
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">文頭</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={memoSuffix}
                    onChange={(e) => {
                      setMemoSuffix(e.target.checked);
                      if (e.target.checked) setMemoPrefix(false);
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">文尾</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={memoSerial}
                    onChange={(e) => setMemoSerial(e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">連番</span>
                </label>
              </div>
            </div>
            <button
              onClick={handleItemNumberApply}
              disabled={!itemNumber && !itemNumber2 && !itemNumberPrefix && !itemNumberSuffix && !itemNumberSerial && !memoPrefix && !memoSuffix && !memoSerial}
              className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                itemNumber || itemNumber2 || itemNumberPrefix || itemNumberSuffix || itemNumberSerial || memoPrefix || memoSuffix || memoSerial
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
            >
              変更
            </button>
          </div>
        </div>
      </div>

      {/* Condition */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Settings className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">商品の状態を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="new">未使用</option>
            <option value="like-new">未使用に近い</option>
            <option value="good">目立った傷や汚れなし</option>
            <option value="fair">やや傷や汚れあり</option>
            <option value="poor">傷や汚れあり</option>
            <option value="bad">全体的に状態が悪い</option>
          </select>
          <button
            onClick={handleConditionApply}
            disabled={!condition}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              condition
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>
    </div>
  );
}