import { MAX_FILE_SIZE, MAX_IMAGES } from './constants';

export interface ImageValidationResult {
  valid: boolean;
  error?: string;
}

export function validateImageFile(file: File): ImageValidationResult {
  if (!file.type.startsWith('image/')) {
    return {
      valid: false,
      error: '画像ファイルのみアップロード可能です'
    };
  }

  if (file.size > MAX_FILE_SIZE) {
    return {
      valid: false,
      error: 'ファイルサイズは10MB以下にしてください'
    };
  }

  return { valid: true };
}

export function validateImageCount(currentCount: number, addCount: number): ImageValidationResult {
  const totalCount = currentCount + addCount;
  
  if (totalCount > MAX_IMAGES) {
    return {
      valid: false,
      error: `画像は最大${MAX_IMAGES}枚までアップロードできます (あと${MAX_IMAGES - currentCount}枚追加可能)`
    };
  }

  return { valid: true };
}

export async function processImageFiles(
  fileList: FileList | null,
  currentImages: string[],
  additionalFiles?: File[],
  preserveOrder: boolean = false
): Promise<string[]> {
  // Calculate remaining slots
  const remainingSlots = MAX_IMAGES - currentImages.length;
  if (remainingSlots <= 0) {
    return currentImages;
  }

  // Convert FileList to array and combine with additional files
  const files = [
    ...(fileList ? Array.from(fileList) : []),
    ...(additionalFiles || [])
  ];

  // Filter valid images
  const validFiles = files.filter(file => validateImageFile(file).valid);

  // Sort by filename only if not preserving order (drag & drop case)
  const processedFiles = preserveOrder 
    ? validFiles.slice(0, remainingSlots)
    : validFiles
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, remainingSlots);

  if (processedFiles.length === 0) {
    return currentImages;
  }

  // Process files sequentially to maintain order
  const newImages: string[] = [];
  for (const file of processedFiles) {
    const base64 = await fileToBase64(file);
    newImages.push(base64);
  }

  // Return combined array of current and new images
  return [...currentImages, ...newImages].slice(0, MAX_IMAGES);
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getOriginalFilename(dataUrl: string): string {
  try {
    // Check if the data URL contains filename metadata
    const metadataMatch = dataUrl.match(/;filename=([^;]+)/);
    if (metadataMatch) {
      return decodeURIComponent(metadataMatch[1]);
    }

    // For base64 images without metadata, get the correct extension
    if (dataUrl.startsWith('data:image/')) {
      const mimeType = dataUrl.split(';')[0].split(':')[1];
      const extension = mimeType.split('/')[1].replace('jpeg', 'jpg');
      return `${Date.now()}.${extension}`;
    }

    // For URLs, extract the filename and extension
    const urlParts = dataUrl.split('/');
    const filename = urlParts[urlParts.length - 1];
    if (filename.includes('.')) {
      const extension = filename.split('.').pop()?.toLowerCase();
      if (extension && ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
        return filename;
      }
    }

    // Default to jpg if no extension could be determined
    return `${Date.now()}.jpg`;
  } catch (error) {
    return `${Date.now()}.jpg`;
  }
}

export function getSequentialFilename(index: number): string {
  return `${String(index).padStart(6, '0')}.jpg`;
}