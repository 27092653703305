import type { Product } from '../types';

export const MAX_TOTAL_LENGTH = 65;

export interface CharacterCount {
  total: number;
  title: number;
  itemNumbers: number;
  memo: number;
  memoReplacement: number;
  isValid: boolean;
}

function countMemoReplacements(text: string, memo: string): number {
  if (!text || !memo) return 0;
  const asteriskCount = (text.match(/\*/g) || []).length;
  return asteriskCount * memo.length;
}

export function calculateCharacterCount(product: Product): CharacterCount {
  // Get base title length (without asterisks)
  const baseTitle = product.title.replace(/\*/g, '');
  const baseTitleLength = baseTitle.length;
  
  // Calculate memo replacements in title
  const titleMemoReplacements = countMemoReplacements(product.title, product.memo);

  // Calculate item number lengths
  let itemNumbersLength = 0;
  let activeNumbers = 0;

  // Process first item number
  if (product.itemNumber && (product.itemNumberPrefix || product.itemNumberSuffix)) {
    let number1 = product.itemNumber;
    if (number1.includes('*') && product.memo) {
      number1 = number1.replace(/\*/g, product.memo);
    }
    if (product.itemNumberSerial) {
      number1 = `${product.serialNumber}${number1}`;
    }
    itemNumbersLength += number1.length;
    activeNumbers++;
  }

  // Process second item number independently
  if (product.itemNumber2 && (product.memoPrefix || product.memoSuffix)) {
    let number2 = product.itemNumber2;
    if (number2.includes('*') && product.memo) {
      number2 = number2.replace(/\*/g, product.memo);
    }
    if (product.memoSerial) {
      number2 = `${number2}${product.serialNumber}`;
    }
    if (!product.itemNumber || number2 !== product.itemNumber) {
      itemNumbersLength += number2.length;
      activeNumbers++;
    }
  }

  // Add spaces between item numbers if there are multiple
  const spacesNeeded = Math.max(0, activeNumbers - 1);
  
  // Calculate total length
  const total = baseTitleLength + 
    titleMemoReplacements + 
    itemNumbersLength + 
    spacesNeeded;

  return {
    total,
    title: baseTitleLength,
    itemNumbers: itemNumbersLength,
    memo: product.memo.length,
    memoReplacement: titleMemoReplacements,
    isValid: total <= MAX_TOTAL_LENGTH
  };
}