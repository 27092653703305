import React, { useState, useRef } from 'react';
import { Upload, Loader2 } from 'lucide-react';
import { BulkImageEditModal } from './BulkImageEditModal';
import { LoadingOverlay } from '../common/LoadingOverlay';
import type { ProcessingProgress } from '../../utils/imageProcessing';
import { processImageGroups } from '../../utils/imageImportUtils';

export function BulkImageImport() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<ProcessingProgress | null>(null);
  const [processedImages, setProcessedImages] = useState<{
    files: File[];
    originals: string[];
    thumbnails: string[];
  } | null>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    setIsLoading(true);
    setError(null);
    setProgress(null);

    try {
      const [group] = await processImageGroups(files, (progress) => {
        setProgress(progress);
      });

      if (!group || group.files.length === 0) {
        throw new Error('有効な画像が見つかりませんでした');
      }

      setProcessedImages({
        files: group.files,
        originals: group.originals,
        thumbnails: group.thumbnails
      });
      setShowEditModal(true);
    } catch (error) {
      console.error('Error processing images:', error);
      setError(error instanceof Error ? error.message : '画像の処理中にエラーが発生しました');
    } finally {
      setIsLoading(false);
      setProgress(null);
    }
  };

  const handleComplete = () => {
    setShowEditModal(false);
    setProcessedImages(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <button
        onClick={() => fileInputRef.current?.click()}
        disabled={isLoading}
        className={`w-full flex items-center justify-center gap-2 px-4 py-3 h-12 rounded-md text-sm font-medium transition-colors ${
          isLoading
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'text-white bg-gradient-to-r from-amber-500 to-orange-600 hover:from-amber-600 hover:to-orange-700'
        }`}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple
          accept="image/*"
          className="hidden"
          onChange={handleFileSelect}
          onClick={e => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
        {isLoading ? (
          <Loader2 className="w-5 h-5 animate-spin" />
        ) : (
          <Upload className="w-5 h-5" />
        )}
        <span>一括で写真をインポート</span>
      </button>

      {error && (
        <div className="mt-2 p-3 bg-red-50 text-red-600 text-sm rounded-md">
          {error}
        </div>
      )}

      {showEditModal && processedImages && (
        <BulkImageEditModal
          images={processedImages}
          onClose={handleComplete}
        />
      )}

      {isLoading && (
        <LoadingOverlay 
          message="商品画像を取り込んでいます。今しばらくお待ちください..."
          progress={progress}
        />
      )}
    </div>
  );
}