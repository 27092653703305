import type { Product } from '../types';

export function processTitleWithMemo(title: string, memo: string): string {
  if (!title) return '';
  if (!memo) return title.replace(/\*/g, '');
  return title.replace(/\*/g, memo);
}

export function processProductTitle(product: Product): string {
  const { title, memo } = product;
  return processTitleWithMemo(title, memo);
}

export function getDisplayTitle(product: Product): string {
  let displayTitle = processProductTitle(product);
  
  // Add item numbers based on position settings and checkbox state
  const itemNumbers = getItemNumberDisplay(product);
  
  // Build the display title with item numbers
  const parts: string[] = [];

  // Add first item number if prefix is enabled
  if (product.itemNumberPrefix && product.itemNumber) {
    const number1 = itemNumbers[0];
    if (number1) parts.push(number1);
  }
  
  // Add second item number if prefix is enabled (independently)
  if (product.memoPrefix && product.itemNumber2) {
    const number2 = itemNumbers[1];
    if (number2) parts.push(number2);
  }

  // Add main title
  parts.push(displayTitle);

  // Add first item number if suffix is enabled
  if (product.itemNumberSuffix && product.itemNumber) {
    const number1 = itemNumbers[0];
    if (number1) parts.push(number1);
  }
  
  // Add second item number if suffix is enabled (independently)
  if (product.memoSuffix && product.itemNumber2) {
    const number2 = itemNumbers[1];
    if (number2) parts.push(number2);
  }

  return parts.join(' ').trim();
}

export function getItemNumberDisplay(product: Product): string[] {
  const numbers: string[] = [];
  
  // Process first item number
  if (product.itemNumber && (product.itemNumberPrefix || product.itemNumberSuffix)) {
    // Replace asterisks in item number with memo if present
    let number1 = product.itemNumber;
    if (number1.includes('*') && product.memo) {
      number1 = number1.replace(/\*/g, product.memo);
    }
    
    const finalNumber1 = product.itemNumberSerial 
      ? `${product.serialNumber}${number1}`
      : number1;
    numbers.push(finalNumber1);
  }
  
  // Process second item number independently
  if (product.itemNumber2 && (product.memoPrefix || product.memoSuffix)) {
    // Replace asterisks in item number 2 with memo if present
    let number2 = product.itemNumber2;
    if (number2.includes('*') && product.memo) {
      number2 = number2.replace(/\*/g, product.memo);
    }
    
    const finalNumber2 = product.memoSerial 
      ? `${number2}${product.serialNumber}` // Append serial number at the end for itemNumber2
      : number2;
    
    numbers.push(finalNumber2);
  }
  
  return numbers;
}