import React from 'react';
import { prefectures } from '../../utils/prefectures';

interface ShippingLocationProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const ShippingLocation: React.FC<ShippingLocationProps> = ({ value, onChange }) => {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">発送元の地域</h4>
      <select
        name="shippingLocation"
        value={value}
        onChange={onChange}
        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
      >
        <option value="">選択してください</option>
        {prefectures.map(prefecture => (
          <option key={prefecture} value={prefecture}>
            {prefecture}
          </option>
        ))}
      </select>
    </div>
  );
};