import React from 'react';
import { Loader2 } from 'lucide-react';
import { ProgressBar } from './ProgressBar';
import type { ProcessingProgress } from '../../utils/imageProcessing';

interface LoadingOverlayProps {
  message: string;
  progress?: ProcessingProgress;
  subMessage?: string;
}

export function LoadingOverlay({ message, progress, subMessage }: LoadingOverlayProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <div className="flex items-center justify-center mb-4">
          <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
        </div>
        
        <p className="text-gray-700 text-center mb-4">{message}</p>
        
        {progress && (
          <div className="space-y-4">
            <ProgressBar
              progress={progress.current}
              total={progress.total}
              label={
                progress.phase === 'reading'
                  ? '画像を読み込み中...'
                  : progress.phase === 'processing'
                  ? '画像を処理中...'
                  : '処理完了'
              }
            />
            {subMessage && (
              <p className="text-sm text-gray-500 text-center mt-2">
                {subMessage}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}