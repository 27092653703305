import React from 'react';

interface ConditionSettingsProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function ConditionSettings({ value, onChange }: ConditionSettingsProps) {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">商品の状態</h4>
      <select
        name="condition"
        value={value}
        onChange={onChange}
        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
      >
        <option value="">選択してください</option>
        <option value="new">未使用</option>
        <option value="like-new">未使用に近い</option>
        <option value="good">目立った傷や汚れなし</option>
        <option value="fair">やや傷や汚れあり</option>
        <option value="poor">傷や汚れあり</option>
        <option value="bad">全体的に状態が悪い</option>
      </select>
    </div>
  );
}