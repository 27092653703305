import React, { useState } from 'react';
import { X, Save } from 'lucide-react';

interface TemplateEditorProps {
  templates: { [key: string]: string };
  onSave: (templates: { [key: string]: string }) => void;
  onClose: () => void;
}

export const TemplateEditor: React.FC<TemplateEditorProps> = ({
  templates,
  onSave,
  onClose
}) => {
  const [editedTemplates, setEditedTemplates] = useState(templates);

  const handleTemplateChange = (condition: string, value: string) => {
    setEditedTemplates(prev => ({
      ...prev,
      [condition]: value
    }));
  };

  const handleSave = () => {
    onSave(editedTemplates);
    onClose();
  };

  const conditions = [
    { key: 'new', label: '新品' },
    { key: 'like-new', label: '未使用に近い' },
    { key: 'good', label: '目立った傷や汚れなし' },
    { key: 'fair', label: 'やや傷や汚れあり' },
    { key: 'poor', label: '傷や汚れあり' },
    { key: 'bad', label: '全体的に状態が悪い' }
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-900">テンプレートを編集</h2>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="space-y-6">
            {conditions.map(({ key, label }) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {label}のテンプレート
                </label>
                <textarea
                  value={editedTemplates[key]}
                  onChange={(e) => handleTemplateChange(key, e.target.value)}
                  rows={10}
                  className="w-full rounded-md border-gray-300 bg-gray-50 font-mono text-sm"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-3 pt-4 mt-4 border-t">
          <button
            onClick={onClose}
            className="btn btn-secondary"
          >
            キャンセル
          </button>
          <button
            onClick={handleSave}
            className="btn btn-primary flex items-center gap-2"
          >
            <Save className="w-4 h-4" />
            <span>保存</span>
          </button>
        </div>
      </div>
    </div>
  );
};