import React, { useState } from 'react';
import { Folder, Plus, Trash2, Layers } from 'lucide-react';
import { useFolderStore } from '../../stores/folderStore';
import { BulkImageImport } from './BulkImageImport';
import { TemplateManager } from '../templates/TemplateManager';

export function FolderList() {
  const [newFolderName, setNewFolderName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const { folders, currentFolderId, createFolder, deleteFolder, setCurrentFolder } = useFolderStore();

  const handleCreateFolder = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newFolderName.trim()) return;

    createFolder(newFolderName);
    setNewFolderName('');
    setIsCreating(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-900">フォルダ</h2>
          <button
            onClick={() => setIsCreating(true)}
            className="flex items-center gap-2 text-sm text-blue-600 hover:text-blue-700"
          >
            <Plus className="w-4 h-4" />
            <span>新規フォルダ</span>
          </button>
        </div>

        <div className="space-y-1">
          <button
            onClick={() => setCurrentFolder(null)}
            className={`w-full flex items-center gap-2 px-3 py-2 rounded-md text-left ${
              currentFolderId === null
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            <Layers className="w-4 h-4" />
            <span>すべてを表示</span>
          </button>
          
          <div className="mt-2 pt-2 border-t">
            {folders.map(folder => (
              <div
                key={folder.id}
                className={`flex items-center gap-2 ${
                  currentFolderId === folder.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-700 hover:bg-gray-50'
                } rounded-md group`}
              >
                <button
                  onClick={() => setCurrentFolder(folder.id)}
                  className="flex-1 flex items-center gap-2 px-3 py-2"
                >
                  <Folder className="w-4 h-4" />
                  <span>{folder.name}</span>
                </button>
                <button
                  onClick={() => deleteFolder(folder.id)}
                  className="px-2 text-gray-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>

          {isCreating && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <form onSubmit={handleCreateFolder} className="space-y-4">
                <div>
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder="フォルダ名を入力"
                    className="w-full rounded-md border-gray-300"
                    autoFocus
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setIsCreating(false);
                      setNewFolderName('');
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 min-w-[90px]"
                  >
                    キャンセル
                  </button>
                  <button
                    type="submit"
                    disabled={!newFolderName.trim()}
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed min-w-[90px]"
                  >
                    作成
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      <TemplateManager />

      <div className="border-t p-4">
        <BulkImageImport />
      </div>
    </div>
  );
}