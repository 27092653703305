export function cleanTitle(title: string): string {
  return title
    .replace(/^["']|["']$/g, '') // Remove quotes if present
    .replace(/^\s*【.*?】\s*/, '') // Remove leading 【】 brackets
    .replace(/\s*【.*?】\s*$/, '') // Remove trailing 【】 brackets
    .replace(/\s+/g, ' ') // Normalize spaces
    .replace(/[^\S\r\n]+/g, ' ') // Replace multiple spaces with single space
    .replace(/\s*[\r\n]+\s*/g, ' ') // Replace newlines with space
    .replace(/状態[：:].+?(?=[、。]|$)/g, '') // Remove condition descriptions
    .replace(/コンディション[：:].+?(?=[、。]|$)/g, '') // Remove condition descriptions
    .replace(/使用感[：:].+?(?=[、。]|$)/g, '') // Remove condition descriptions
    .slice(0, 65)
    .trim();
}

export function validateTitle(title: string): boolean {
  if (!title) return false;
  if (title.length > 65) return false;
  if (/^[\s\d]+$/.test(title)) return false; // Only spaces or numbers
  if (/状態[:：]|コンディション[:：]|使用感[:：]/.test(title)) return false; // Contains condition descriptions
  return true;
}