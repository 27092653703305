import { PREDEFINED_SHIPPING_METHODS } from './shippingConstants';
import type { Product } from '../types';

export function getShippingMethodsText(product: Product): string {
  const methods = [];
  
  // Yamato Transport methods
  if (product.nekoposu) methods.push('ネコポス');
  if (product.nekoCompact) methods.push('宅急便コンパクト');
  if (product.nekoEazy) {
    const size = product.nekoEazySize ? `${product.nekoEazySize}サイズ` : '';
    const weight = product.nekoEazyWeight ? `${product.nekoEazyWeight}kg` : '';
    const details = [size, weight].filter(Boolean).join('、');
    methods.push(`宅急便${details ? `（${details}）` : ''}`);
  }

  // Japan Post methods
  if (product.yuPacketMini) methods.push('ゆうパケットポストmini');
  if (product.yuPacket) methods.push('ゆうパケット');
  if (product.yuPacketPlus) methods.push('ゆうパケットプラス');
  if (product.yuPack) {
    const size = product.yuPackSize ? `${product.yuPackSize}サイズ` : '';
    const weight = product.yuPackWeight ? `${product.yuPackWeight}kg` : '';
    const details = [size, weight].filter(Boolean).join('、');
    methods.push(`ゆうパック${details ? `（${details}）` : ''}`);
  }

  // Custom shipping methods
  if (product.customShippingMethods?.length > 0) {
    product.customShippingMethods.forEach(method => {
      if (method.name === 'other' && method.carrierName) {
        // For 'other' type, use the carrier name (limited to 15 chars)
        methods.push(method.carrierName.slice(0, 15));
      } else if (method.name) {
        // For predefined methods, use the name as is
        const predefinedMethod = PREDEFINED_SHIPPING_METHODS.find(m => m.value === method.name);
        if (predefinedMethod) {
          methods.push(predefinedMethod.label);
        }
      }
    });
  }

  if (methods.length === 0) {
    return '';
  }

  return `配送方法：\n${methods.map(method => `・${method}`).join('\n')}`;
}

export function updateDescriptionWithShippingMethods(description: string, product: Product): string {
  // Remove existing shipping methods section
  const shippingIndex = description.indexOf('\n\n配送方法：');
  const cleanDescription = shippingIndex !== -1 
    ? description.substring(0, shippingIndex)
    : description;

  // Add shipping methods
  const shippingText = getShippingMethodsText(product);
  return shippingText ? `${cleanDescription}\n\n${shippingText}` : cleanDescription;
}