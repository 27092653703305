import React, { useState, useEffect, useRef } from 'react';
import { Pencil } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { calculateCharacterCount } from '../utils/characterCount';
import type { Product } from '../types';

interface EditableTitleProps {
  title: string;
  isGenerating: boolean;
  product: Product;
  onTitleChange: (newTitle: string) => void;
  onEditStateChange?: (isEditing: boolean) => void;
}

export function EditableTitle({ 
  title, 
  isGenerating,
  product,
  onTitleChange,
  onEditStateChange 
}: EditableTitleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    onEditStateChange?.(isEditing);
  }, [isEditing, onEditStateChange]);

  // Calculate character count for editing mode
  const charCount = calculateCharacterCount({
    ...product,
    title: editedTitle,
    // Disable item numbers during editing to avoid double counting
    itemNumber: '',
    itemNumber2: '',
    itemNumberPrefix: false,
    itemNumberSuffix: false,
    memoPrefix: false,
    memoSuffix: false
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedTitle(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editedTitle !== title) {
      onTitleChange(editedTitle);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleBlur();
    }
    if (e.key === 'Escape') {
      setEditedTitle(title);
      setIsEditing(false);
      onEditStateChange?.(false);
    }
  };

  if (isEditing) {
    return (
      <div className="space-y-2">
        <textarea
          ref={inputRef}
          value={editedTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={`w-full text-base font-medium text-gray-900 border rounded p-2 focus:outline-none focus:ring-2 resize-none ${
            charCount.isValid 
              ? 'border-blue-500 focus:ring-blue-500' 
              : 'border-red-500 focus:ring-red-500'
          }`}
          rows={3}
          maxLength={65}
        />
        <div className={`text-sm flex justify-end ${
          charCount.isValid ? 'text-gray-500' : 'text-red-600 font-medium'
        }`}>
          {charCount.total}/65 文字
        </div>
      </div>
    );
  }

  return (
    <div className="group relative flex items-start gap-1">
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (!isGenerating) {
            setIsEditing(true);
            onEditStateChange?.(true);
          }
        }}
        className="flex-shrink-0 p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full transition-colors mt-0.5"
      >
        <Pencil className="w-4 h-4" />
      </button>
      <h3 className="flex-1 text-base font-medium text-gray-900 line-clamp-3">
        {isGenerating ? (
          <motion.div className="flex flex-wrap overflow-hidden">
            {title.split('').map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 1 }}
                animate={{
                  opacity: [1, 0.3, 1],
                  y: [0, -2, 0],
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: index * 0.05,
                  ease: "easeInOut"
                }}
                className="inline-block"
                style={{
                  textShadow: '0 0 8px rgba(59, 130, 246, 0.5)',
                }}
              >
                {char === ' ' ? '\u00A0' : char}
              </motion.span>
            ))}
          </motion.div>
        ) : (
          <span>{title || '(タイトルなし)'}</span>
        )}
      </h3>
    </div>
  );
}