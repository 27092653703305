import React from 'react';

interface SizeSelectorProps {
  name: string;
  size?: string;
  weight?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SizeSelector: React.FC<SizeSelectorProps> = ({
  name,
  size,
  weight,
  onChange
}) => {
  const isYuPack = name === 'yuPack';
  const sizeOptions = isYuPack 
    ? ['60', '80', '100', '120', '140', '160']
    : ['60', '80', '100', '120', '140', '160', '180', '200'];
  
  const weightOptions = isYuPack
    ? ['2', '5', '10', '15', '20', '25']
    : ['2', '5', '10', '15', '20', '25', '30'];

  return (
    <div className="mt-3 pl-8 space-y-3">
      <div>
        <label className="block text-sm text-gray-600 mb-1">梱包後の縦・横・高さの合計</label>
        <select
          name={`${name}Size`}
          value={size}
          onChange={onChange}
          className="w-full rounded-md border-gray-300 bg-gray-50"
        >
          <option value="">選択してください</option>
          {sizeOptions.map(s => (
            <option key={s} value={s}>{s}cm</option>
          ))}
        </select>
      </div>
      <div>
        <label className="block text-sm text-gray-600 mb-1">梱包後の重さ</label>
        <select
          name={`${name}Weight`}
          value={weight}
          onChange={onChange}
          className="w-full rounded-md border-gray-300 bg-gray-50"
        >
          <option value="">選択してください</option>
          {weightOptions.map(w => (
            <option key={w} value={w}>{w}kg</option>
          ))}
        </select>
      </div>
      {size && weight && (
        <div className="text-sm text-gray-600">
          算出された配送サイズ: {Math.max(Number(size), Number(weight) > 5 ? 80 : 60)}サイズ
        </div>
      )}
    </div>
  );
};