import React from 'react';
import { AlertTriangle } from 'lucide-react';

interface DeleteConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  productTitle: string;
}

export function DeleteConfirmationModal({ onConfirm, onCancel, productTitle }: DeleteConfirmationModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center gap-3 mb-4">
          <div className="flex-shrink-0">
            <AlertTriangle className="w-6 h-6 text-red-600" />
          </div>
          <h3 className="text-lg font-medium text-gray-900">商品を削除</h3>
        </div>
        
        <p className="text-sm text-gray-600 mb-4">
          「{productTitle}」を削除してもよろしいですか？
          <br />
          この操作は取り消せません。
        </p>

        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="btn btn-secondary"
          >
            キャンセル
          </button>
          <button
            onClick={onConfirm}
            className="btn btn-danger"
          >
            削除する
          </button>
        </div>
      </div>
    </div>
  );
}