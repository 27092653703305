import JSZip from 'jszip';
import { Product } from '../types';
import { generateCSV } from './csvExport';
import { getSequentialFilename } from './imageUtils';

export async function compressImage(imageData: string, quality: number = 0.7, maxWidth: number = 1200): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      
      img.onload = () => {
        try {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions if width exceeds maxWidth
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          // Use better quality settings
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';

          // Draw image with white background to handle transparency
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to JPEG with specified quality
          const compressedData = canvas.toDataURL('image/jpeg', quality);
          resolve(compressedData);
        } catch (error) {
          reject(new Error('Failed to process image in canvas'));
        }
      };

      img.onerror = () => reject(new Error('Failed to load image'));

      // Handle both data URLs and regular URLs
      if (imageData.startsWith('data:')) {
        img.src = imageData;
      } else {
        // For regular URLs, load as blob first to handle CORS
        fetch(imageData)
          .then(response => response.blob())
          .then(blob => {
            img.src = URL.createObjectURL(blob);
          })
          .catch(() => {
            // Fallback to direct loading if fetch fails
            img.src = imageData;
          });
      }
    } catch (error) {
      reject(new Error('Failed to initialize image compression'));
    }
  });
}

export async function createImageZip(images: string[], products: Product[]): Promise<Blob> {
  const zip = new JSZip();
  const folder = zip.folder('yahoo_auction_data');
  
  if (!folder) {
    throw new Error('Failed to create ZIP folder');
  }

  let currentImageNumber = 1;
  const failedImages: number[] = [];

  // Process images sequentially to maintain order
  for (let i = 0; i < images.length; i++) {
    try {
      const imageData = images[i];
      const compressedImage = await compressImage(imageData);
      const base64Data = compressedImage.split(',')[1];
      const filename = getSequentialFilename(currentImageNumber++);
      folder.file(filename, base64Data, { base64: true });
    } catch (error) {
      console.error(`Failed to compress image ${i + 1}:`, error);
      failedImages.push(i + 1);
    }
  }

  // Add CSV file
  const csvContent = generateCSV(products);
  folder.file('products.csv', '\uFEFF' + csvContent, { binary: false });

  // Add compression report if there were failures
  if (failedImages.length > 0) {
    const report = `Failed to compress the following images:\n${failedImages.join(', ')}`;
    folder.file('compression_report.txt', report);
  }

  return zip.generateAsync({ 
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {
      level: 6
    }
  });
}

export function downloadZip(blob: Blob, filename: string = 'yahoo_auction.zip'): void {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}