import { Product } from '../../types';
import { CSVOptions, CSVRow } from '../../types/csv';
import { CSV_HEADERS } from './headers';
import { formatProductRow, resetImageCounter } from './formatters';

function escapeField(field: string | number | undefined | null): string {
  if (field === undefined || field === null) return '';
  const stringField = String(field);
  // Replace newlines with <br> for Excel compatibility
  const escapedField = stringField.replace(/\n/g, '<br>');
  // Double up quotes if field contains quotes or commas
  return stringField.includes(',') || stringField.includes('"') 
    ? `"${escapedField.replace(/"/g, '""')}"` 
    : escapedField;
}

export function generateCSV(products: Product[], options: CSVOptions = {}): string {
  const { includeHeaders = true } = options;
  const rows: string[] = [];

  // Reset image counter before processing products
  resetImageCounter();

  // Add headers
  if (includeHeaders) {
    const headerRow = CSV_HEADERS.map(header => header.label).join(',');
    rows.push(headerRow);
  }

  // Add data rows
  products.forEach(product => {
    const formattedRow = formatProductRow(product);
    const escapedRow = formattedRow.map(field => escapeField(field));
    rows.push(escapedRow.join(','));
  });

  return rows.join('\r\n');
}