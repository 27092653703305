import React, { useState, useEffect } from 'react';
import { Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { ImagePopup } from './ImagePopup';

interface DraggableImageGridProps {
  images: string[];
  groupSize: number;
  groupIndex: number;
  title?: string;
  onDeleteImage?: (imageIndex: number) => void;
  onReorderImages?: (fromIndex: number, toIndex: number) => void;
}

export function DraggableImageGrid({
  images = [],
  groupSize,
  groupIndex,
  title,
  onDeleteImage,
  onReorderImages
}: DraggableImageGridProps) {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [previewIndex, setPreviewIndex] = useState<number | null>(null);
  const displayImages = images.slice(0, groupSize);

  const getGridColumns = (count: number) => {
    if (count <= 3) return 'grid-cols-3';
    if (count <= 4) return 'grid-cols-4';
    return 'grid-cols-5';
  };

  const createDragPreview = (imageUrl: string): HTMLImageElement => {
    const preview = new Image();
    preview.src = imageUrl;
    preview.className = 'drag-preview';
    preview.style.width = '24px';
    preview.style.height = '24px';
    preview.style.objectFit = 'cover';
    preview.style.borderRadius = '4px';
    preview.style.opacity = '0.6';
    preview.style.pointerEvents = 'none';
    preview.style.position = 'fixed';
    preview.style.zIndex = '9999';
    preview.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
    preview.style.border = '1px solid rgba(59, 130, 246, 0.5)';
    preview.style.backgroundColor = 'white';
    return preview;
  };

  const handleDragStart = (e: React.DragEvent, index: number) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    
    const preview = createDragPreview(displayImages[index]);
    document.body.appendChild(preview);
    
    e.dataTransfer.setDragImage(preview, 12, 12);
    
    requestAnimationFrame(() => {
      document.body.removeChild(preview);
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e: React.DragEvent, dropIndex: number) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex || !onReorderImages) return;
    onReorderImages(draggedIndex, dropIndex);
    setDraggedIndex(null);
  };

  const handleMoveImage = (index: number, direction: 'left' | 'right') => {
    if (!onReorderImages) return;
    
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < displayImages.length) {
      onReorderImages(index, newIndex);
    }
  };

  // Handle keyboard navigation for image preview
  useEffect(() => {
    if (previewIndex === null) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setPreviewIndex(null);
      } else if (e.key === 'ArrowLeft' && previewIndex > 0) {
        setPreviewIndex(previewIndex - 1);
      } else if (e.key === 'ArrowRight' && previewIndex < displayImages.length - 1) {
        setPreviewIndex(previewIndex + 1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [previewIndex, displayImages.length]);

  return (
    <>
      <div className={`grid ${getGridColumns(displayImages.length)} gap-4`}>
        {displayImages.map((image, index) => (
          <div
            key={`${groupIndex}-${index}-${image}`}
            className={`relative aspect-square group ${
              index === 0 ? 'col-span-2 row-span-2' : ''
            }`}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            onDragEnd={() => setDraggedIndex(null)}
          >
            <img
              src={image}
              alt={`商品画像 ${index + 1}`}
              className={`w-full h-full object-cover rounded-lg border-2 transition-all duration-200 ${
                draggedIndex === index 
                  ? 'border-blue-500 opacity-50 scale-95'
                  : 'border-gray-200 hover:border-blue-300'
              } cursor-pointer`}
              onClick={() => setPreviewIndex(index)}
            />
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity">
              <div className="absolute inset-0 bg-black bg-opacity-30"></div>
              
              <div className="absolute inset-y-0 left-2 flex items-center">
                {index > 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveImage(index, 'left');
                    }}
                    className="p-2 bg-white rounded-full text-gray-700 hover:bg-gray-100 transition-colors shadow-lg"
                  >
                    <ChevronLeft className="w-5 h-5" />
                  </button>
                )}
              </div>
              <div className="absolute inset-y-0 right-2 flex items-center">
                {index < displayImages.length - 1 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMoveImage(index, 'right');
                    }}
                    className="p-2 bg-white rounded-full text-gray-700 hover:bg-gray-100 transition-colors shadow-lg"
                  >
                    <ChevronRight className="w-5 h-5" />
                  </button>
                )}
              </div>

              {onDeleteImage && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteImage(index);
                  }}
                  className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-600 z-10 shadow-lg"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              )}
            </div>

            {index === 0 && (
              <div className="absolute inset-0 border-4 border-blue-500 rounded-lg opacity-50" />
            )}
            <div className="absolute bottom-2 right-2 px-2 py-1 rounded bg-black bg-opacity-75 text-white text-xs">
              {index + 1}
            </div>
          </div>
        ))}
      </div>

      {previewIndex !== null && (
        <ImagePopup
          images={displayImages}
          currentIndex={previewIndex}
          title={title}
          onClose={() => setPreviewIndex(null)}
          onPrevious={() => setPreviewIndex(prev => Math.max(0, prev - 1))}
          onNext={() => setPreviewIndex(prev => Math.min(displayImages.length - 1, prev + 1))}
        />
      )}
    </>
  );
}