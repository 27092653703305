import React, { useState, useEffect } from 'react';
import { Settings } from 'lucide-react';
import { loadTemplates, saveTemplates } from '../utils/templateStore';
import { TemplateEditor } from './TemplateEditor';
import { getTemplateForCondition } from '../utils/templates/descriptionTemplates';
import type { Product } from '../types';

interface DescriptionInputProps {
  description: string;
  condition: string;
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onTemplateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  useTemplate: boolean;
}

export const DescriptionInput: React.FC<DescriptionInputProps> = ({
  description,
  condition,
  product,
  onChange,
  onTemplateChange,
  useTemplate
}) => {
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);
  const [templates, setTemplates] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setTemplates(loadTemplates());
  }, []);

  // Update description when shipping methods change and template is in use
  useEffect(() => {
    if (useTemplate && condition) {
      const updatedTemplate = getTemplateForCondition(product, condition, templates);
      onChange({
        target: { name: 'description', value: updatedTemplate }
      } as React.ChangeEvent<HTMLTextAreaElement>);
    }
  }, [
    useTemplate,
    condition,
    product.nekoposu,
    product.nekoCompact,
    product.nekoEazy,
    product.nekoEazySize,
    product.nekoEazyWeight,
    product.yuPacketMini,
    product.yuPacket,
    product.yuPacketPlus,
    product.yuPack,
    product.yuPackSize,
    product.yuPackWeight,
    JSON.stringify(product.customShippingMethods)
  ]);

  const handleSaveTemplates = (newTemplates: { [key: string]: string }) => {
    setTemplates(newTemplates);
    saveTemplates(newTemplates);
  };

  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <div className="flex items-center justify-between mb-4">
        <h4 className="font-medium">商品説明</h4>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setShowTemplateEditor(true)}
            className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
          >
            <Settings className="w-4 h-4" />
            <span>テンプレートを編集</span>
          </button>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={useTemplate}
              onChange={onTemplateChange}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">状態に合わせたテンプレを使用</span>
          </label>
        </div>
      </div>
      <textarea
        name="description"
        value={useTemplate && condition ? getTemplateForCondition(product, condition, templates) : description}
        onChange={onChange}
        rows={12}
        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 font-mono text-sm"
        placeholder="商品の説明を入力してください"
        disabled={useTemplate && condition !== ''}
      />

      {showTemplateEditor && (
        <TemplateEditor
          templates={templates}
          onSave={handleSaveTemplates}
          onClose={() => setShowTemplateEditor(false)}
        />
      )}
    </div>
  );
};