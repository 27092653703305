import React, { useState } from 'react';
import { Trash2, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ImageWithTooltip } from './ImageWithTooltip';

interface DraggableProductImagesProps {
  images: string[];
  onImagesChange: (images: string[]) => void;
  onPreview?: (index: number) => void;
}

export function DraggableProductImages({ 
  images, 
  onImagesChange,
  onPreview 
}: DraggableProductImagesProps) {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const handleDragStart = (e: React.DragEvent, index: number) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    
    // Create a preview with updated size
    const img = new Image();
    img.src = images[index];
    img.style.width = '100px';
    img.style.height = '100px';
    img.style.objectFit = 'cover';
    img.style.borderRadius = '4px';
    img.style.opacity = '0.8';
    img.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
    document.body.appendChild(img);
    e.dataTransfer.setDragImage(img, 50, 50); // Adjust offset for new size
    
    requestAnimationFrame(() => {
      document.body.removeChild(img);
    });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e: React.DragEvent, dropIndex: number) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;
    
    const newImages = [...images];
    const [movedImage] = newImages.splice(draggedIndex, 1);
    newImages.splice(dropIndex, 0, movedImage);
    onImagesChange(newImages);
    setDraggedIndex(null);
  };

  const handleMoveImage = (index: number, direction: 'left' | 'right') => {
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < images.length) {
      const newImages = [...images];
      const [movedImage] = newImages.splice(index, 1);
      newImages.splice(newIndex, 0, movedImage);
      onImagesChange(newImages);
    }
  };

  const handleRemoveImage = (index: number) => {
    onImagesChange(images.filter((_, i) => i !== index));
  };

  return (
    <div className="grid grid-cols-5 gap-4">
      {images.map((image, index) => (
        <motion.div
          key={`${image}-${index}`}
          className="relative aspect-square group"
          initial={false}
          animate={draggedIndex === index ? { opacity: 0.5 } : { opacity: 1 }}
          transition={{ duration: 0.2 }}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
          onDragEnd={() => setDraggedIndex(null)}
        >
          <ImageWithTooltip
            src={image}
            alt={`商品画像 ${index + 1}`}
            onClick={() => onPreview?.(index)}
            onDelete={() => handleRemoveImage(index)}
            className={draggedIndex === index ? 'opacity-50' : ''}
          />
          
          <AnimatePresence>
            {draggedIndex !== index && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <div className="absolute inset-0 bg-black bg-opacity-5 rounded-lg"></div>
                
                {/* Navigation Controls */}
                <div className="absolute inset-y-0 left-2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity">
                  {index > 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveImage(index, 'left');
                      }}
                      className="p-1 bg-white/90 hover:bg-white rounded-full text-gray-700 transition-colors shadow-sm"
                    >
                      <ChevronLeft className="w-4 h-4" />
                    </button>
                  )}
                </div>
                <div className="absolute inset-y-0 right-2 flex items-center opacity-0 group-hover:opacity-100 transition-opacity">
                  {index < images.length - 1 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveImage(index, 'right');
                      }}
                      className="p-1 bg-white/90 hover:bg-white rounded-full text-gray-700 transition-colors shadow-sm"
                    >
                      <ChevronRight className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
    </div>
  );
}