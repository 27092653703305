import React from 'react';
import { ShippingMethodButton } from './ShippingMethodButton';
import type { Product } from '../../types';

interface JapanPostShippingMethodsProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const JapanPostShippingMethods: React.FC<JapanPostShippingMethodsProps> = ({
  product,
  onChange
}) => {
  const handleSizeChange = (size: string) => {
    onChange({
      target: { name: 'yuPackSize', value: size }
    } as React.ChangeEvent<HTMLSelectElement>);
  };

  const handleWeightChange = (weight: string) => {
    onChange({
      target: { name: 'yuPackWeight', value: weight }
    } as React.ChangeEvent<HTMLSelectElement>);
  };

  return (
    <div className="space-y-4">
      <h5 className="text-sm font-medium text-gray-700">日本郵便</h5>
      <div className="space-y-4">
        <ShippingMethodButton
          name="yuPacketMini"
          label="ゆうパケットポストmini"
          price="全国一律 180円"
          checked={product.yuPacketMini}
          onChange={onChange}
        />
        <ShippingMethodButton
          name="yuPacket"
          label="ゆうパケット"
          price="全国一律 230円"
          checked={product.yuPacket}
          onChange={onChange}
        />
        <ShippingMethodButton
          name="yuPacketPlus"
          label="ゆうパケットプラス"
          price="全国一律 410円"
          checked={product.yuPacketPlus}
          onChange={onChange}
        />
        <ShippingMethodButton
          name="yuPack"
          label="ゆうパック"
          price="750円〜"
          checked={product.yuPack}
          onChange={onChange}
          showSizeSelector={true}
          size={product.yuPackSize}
          weight={product.yuPackWeight}
          onSizeChange={handleSizeChange}
          onWeightChange={handleWeightChange}
          shippingLater={product.shippingLater}
        />
      </div>
    </div>
  );
};