import { CSVHeader } from '../../types/csv';

export const CSV_HEADERS: CSVHeader[] = [
  { id: 'categoryId', label: 'カテゴリ', description: 'ヤフオクのカテゴリID' },
  { id: 'title', label: 'タイトル', description: '商品名 (65文字以内)' },
  { id: 'description', label: '説明', description: '商品の説明文' },
  { id: 'startPrice', label: '開始価格', description: '開始価格 (税込)' },
  { id: 'buyItNowPrice', label: '即決価格', description: '即決価格 (税込)' },
  { id: 'quantity', label: '個数', description: '出品個数' },
  { id: 'auctionDuration', label: '開催期間', description: 'オークション期間 (日数)' },
  { id: 'endTime', label: '終了時間', description: '終了時刻 (24時間表記)' },
  { id: 'janCode', label: 'JANコード', description: '商品のJANコード (任意)' }
];

// Image headers (10 sets)
for (let i = 1; i <= 10; i++) {
  CSV_HEADERS.push(
    { id: `image${i}`, label: `画像${i}`, description: `商品画像${i}のファイル名` },
    { id: `image${i}Comment`, label: `画像${i}コメント`, description: `商品画像${i}の説明文 (任意)` }
  );
}

// Add remaining headers in exact order
CSV_HEADERS.push(
  { id: 'shippingLocation', label: '商品発送元の都道府県', description: '発送元の都道府県' },
  { id: 'shippingCity', label: '商品発送元の市区町村', description: '発送元の市区町村 (任意)' },
  { id: 'shippingPayer', label: '送料負担', description: '送料負担者 (出品者/落札者)' },
  { id: 'paymentMethod', label: '代金支払い', description: '対応する支払い方法' },
  { id: 'easyPayment', label: 'Yahoo!かんたん決済', description: 'かんたん決済の利用有無' },
  { id: 'easyTrade', label: 'かんたん取引', description: 'かんたん取引の利用有無' },
  { id: 'cashOnDelivery', label: '商品代引', description: '代引きの可否' },
  { id: 'condition', label: '商品の状態', description: '商品の状態 (必須)' },
  { id: 'conditionNote', label: '商品の状態備考', description: '状態の補足説明 (任意)' },
  { id: 'returnsAccepted', label: '返品の可否', description: '返品可/返品不可' },
  { id: 'returnsNote', label: '返品の可否備考', description: '返品条件の補足説明 (任意)' },
  { id: 'ratingRestriction', label: '入札者評価制限', description: '評価による入札制限' },
  { id: 'badRatingRestriction', label: '悪い評価の割合での制限', description: '悪い評価割合による制限' },
  { id: 'bidderAuthentication', label: '入札者認証制限', description: '認証による入札制限' },
  { id: 'autoExtension', label: '自動延長', description: '自動延長の設定' },
  { id: 'earlyTermination', label: '早期終了', description: '早期終了の可否' },
  { id: 'priceNegotiation', label: '値下げ交渉', description: '値下げ交渉の可否' },
  { id: 'autoRelist', label: '自動再出品', description: '自動再出品の回数' },
  { id: 'autoRelistDiscount', label: '自動値下げ', description: '自動値下げの設定' },
  { id: 'autoRelistDiscountRate', label: '自動値下げ価格変更率', description: '自動値下げの割合 (%)' },
  { id: 'featured', label: '注目のオークション', description: '注目オークションの設定金額' },
  { id: 'recommended', label: 'おすすめコレクション', description: 'おすすめ表示の確率 (%)' },
  { id: 'shippingFixed', label: '送料固定', description: '送料の固定設定' },
  { id: 'packageSize', label: '荷物の大きさ', description: '荷物サイズの合計 (cm)' },
  { id: 'packageWeight', label: '荷物の重量', description: '荷物の重量 (kg)' },
  { id: 'nekoposu', label: 'ネコポス', description: 'ネコポスの利用有無' },
  { id: 'nekoCompact', label: 'ネコ宅急便コンパクト', description: 'ネコ宅急便コンパクトの利用有無' },
  { id: 'nekoEazy', label: 'ネコ宅急便', description: 'ネコ宅急便の利用有無' },
  { id: 'yuPacket', label: 'ゆうパケット', description: 'ゆうパケットの利用有無' },
  { id: 'yuPack', label: 'ゆうパック', description: 'ゆうパックの利用有無' },
  { id: 'yuPacketMini', label: 'ゆうパケットポストmini', description: 'ゆうパケットポストminiの利用有無' },
  { id: 'yuPacketPlus', label: 'ゆうパケットプラス', description: 'ゆうパケットプラスの利用有無' },
  { id: 'shippingTime', label: '発送までの日数', description: '発送日数の目安' }
);

// Shipping method headers (10 sets)
for (let i = 1; i <= 10; i++) {
  CSV_HEADERS.push(
    { id: `shippingMethod${i}`, label: `配送方法${i}`, description: `配送方法${i}の名称` },
    { id: `shippingPrice${i}`, label: `配送方法${i}全国一律価格`, description: `配送方法${i}の全国一律料金` },
    { id: `shippingHokkaido${i}`, label: `北海道料金${i}`, description: `配送方法${i}の北海道向け料金` },
    { id: `shippingOkinawa${i}`, label: `沖縄料金${i}`, description: `配送方法${i}の沖縄向け料金` },
    { id: `shippingIsland${i}`, label: `離島料金${i}`, description: `配送方法${i}の離島向け料金` }
  );
}

// Final headers
CSV_HEADERS.push(
  { id: 'postPayment', label: '受け取り後決済サービス', description: '受け取り後決済サービスの利用有無' },
  { id: 'internationalShipping', label: '海外発送', description: '海外発送の可否' }
);