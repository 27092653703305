import React from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { ZoomableImage } from '../images/ZoomableImage';

interface ImagePopupProps {
  images: string[];
  currentIndex: number;
  title?: string;
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export function ImagePopup({
  images,
  currentIndex,
  title,
  onClose,
  onPrevious,
  onNext
}: ImagePopupProps) {
  const [isZoomed, setIsZoomed] = React.useState(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (isZoomed) return;
    
    switch (e.key) {
      case 'ArrowLeft':
        if (currentIndex > 0) onPrevious();
        break;
      case 'ArrowRight':
        if (currentIndex < images.length - 1) onNext();
        break;
      case 'Escape':
        onClose();
        break;
    }
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50"
        onClick={handleBackdropClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {/* Title */}
        {title && (
          <div className="absolute top-0 left-0 right-0 p-4 bg-gradient-to-b from-black/80 to-transparent">
            <h2 className="text-white text-lg font-medium text-center max-w-4xl mx-auto">
              {title}
            </h2>
          </div>
        )}

        {/* Close button */}
        <button
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          className="absolute top-4 right-4 text-white hover:text-gray-300 z-50"
        >
          <X className="w-8 h-8" />
        </button>

        {/* Navigation buttons */}
        {!isZoomed && (
          <>
            {currentIndex > 0 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onPrevious();
                }}
                className="absolute left-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300"
              >
                <ChevronLeft className="w-12 h-12" />
              </button>
            )}

            {currentIndex < images.length - 1 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onNext();
                }}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-white hover:text-gray-300"
              >
                <ChevronRight className="w-12 h-12" />
              </button>
            )}
          </>
        )}

        {/* Main image */}
        <div 
          className="relative max-w-[90vw] max-h-[90vh] overflow-hidden"
          onClick={e => e.stopPropagation()}
        >
          <ZoomableImage
            src={images[currentIndex]}
            alt={`商品画像 ${currentIndex + 1}`}
            onZoom={setIsZoomed}
          />

          {/* Image counter */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-50 px-3 py-1 rounded-full">
            {currentIndex + 1} / {images.length}
          </div>
        </div>

        {/* Help text */}
        <div className="absolute bottom-4 left-4 text-white text-sm space-y-1">
          <div className="bg-black bg-opacity-50 px-2 py-1 rounded">
            クリックまたはホイールで拡大/縮小
          </div>
          {!isZoomed && (
            <>
              <div className="bg-black bg-opacity-50 px-2 py-1 rounded">
                <kbd className="px-1 bg-gray-700 rounded">←</kbd>
                <kbd className="px-1 bg-gray-700 rounded ml-1">→</kbd> 画像切替
              </div>
              <div className="bg-black bg-opacity-50 px-2 py-1 rounded">
                <kbd className="px-1 bg-gray-700 rounded">ESC</kbd> 閉じる
              </div>
            </>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}