import type { RetryOptions } from './types';

const DEFAULT_RETRY_OPTIONS: RetryOptions = {
  maxRetries: 3,
  initialDelay: 1000,
  maxDelay: 10000,
  backoffFactor: 2
};

export async function withRetry<T>(
  operation: () => Promise<T>,
  options: Partial<RetryOptions> = {}
): Promise<T> {
  const retryOptions = { ...DEFAULT_RETRY_OPTIONS, ...options };
  let lastError: Error;
  let delay = retryOptions.initialDelay;

  for (let attempt = 0; attempt <= retryOptions.maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error: any) {
      lastError = error;

      if (attempt === retryOptions.maxRetries) break;
      if (!shouldRetry(error)) throw error;

      await sleep(delay);
      delay = Math.min(delay * retryOptions.backoffFactor, retryOptions.maxDelay);
    }
  }

  throw lastError;
}

function shouldRetry(error: any): boolean {
  const retryableErrors = [
    'rate_limit_exceeded',
    'timeout',
    'service_unavailable',
    'internal_server_error'
  ];

  return retryableErrors.includes(error?.error?.code) ||
    error?.error?.type === 'server_error' ||
    error?.response?.status === 429 ||
    error?.response?.status >= 500;
}

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}