import { create } from 'zustand';
import type { Folder, FolderState } from '../types/folder';
import { createFolder as dbCreateFolder, getFoldersByUser, deleteFolder as dbDeleteFolder } from '../db';
import { useAuthStore } from './authStore';

export const useFolderStore = create<FolderState>((set, get) => ({
  folders: [],
  currentFolderId: null,

  loadFolders: async () => {
    const user = useAuthStore.getState().user;
    if (!user) return;

    const folders = await getFoldersByUser(user.email);
    // Sort folders by creation date
    const sortedFolders = [...folders].sort((a, b) => 
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    set({ folders: sortedFolders });
  },

  createFolder: async (name: string) => {
    const user = useAuthStore.getState().user;
    if (!user) return;

    const folder: Omit<Folder, 'id'> = {
      name,
      createdAt: new Date(),
      userId: user.email
    };
    
    const newId = await dbCreateFolder(folder, user.email);
    const newFolder = { ...folder, id: newId };

    set(state => {
      // Insert the new folder after the main folder (which is shown first)
      const updatedFolders = [...state.folders];
      updatedFolders.splice(0, 0, newFolder); // Insert at the beginning
      
      return {
        folders: updatedFolders,
        currentFolderId: newFolder.id
      };
    });
  },

  deleteFolder: async (id: number) => {
    const user = useAuthStore.getState().user;
    if (!user) return;

    await dbDeleteFolder(id, user.email);
    set(state => ({
      folders: state.folders.filter(f => f.id !== id),
      currentFolderId: state.currentFolderId === id ? null : state.currentFolderId
    }));
  },

  setCurrentFolder: (id: number | null) => {
    set({ currentFolderId: id });
  }
}));