import { STORES, INDEXES } from '../config';
import { executeTransaction } from './baseOperations';
import type { Folder } from '../../types/folder';

export async function createFolder(folder: Omit<Folder, 'id'>, userId: string): Promise<number> {
  return executeTransaction(STORES.FOLDERS, 'readwrite', async (tx) => {
    return await tx.objectStore(STORES.FOLDERS).add({
      ...folder,
      userId,
      createdAt: new Date().toISOString()
    });
  });
}

export async function getFoldersByUser(userId: string): Promise<Folder[]> {
  return executeTransaction(STORES.FOLDERS, 'readonly', async (tx) => {
    const folders = await tx.objectStore(STORES.FOLDERS)
      .index(INDEXES.USER_ID)
      .getAll(userId);
    
    return folders.sort((a, b) => 
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  });
}

export async function deleteFolder(id: number, userId: string): Promise<void> {
  return executeTransaction([STORES.FOLDERS, STORES.PRODUCTS], 'readwrite', async (tx) => {
    // Delete the folder
    await tx.objectStore(STORES.FOLDERS).delete(id);

    // Update products in the deleted folder
    const products = await tx.objectStore(STORES.PRODUCTS)
      .index(INDEXES.USER_ID)
      .getAll(userId);

    await Promise.all(
      products
        .filter(p => p.folderId === id)
        .map(p => tx.objectStore(STORES.PRODUCTS).put({
          ...p,
          folderId: null,
          updatedAt: new Date().toISOString()
        }))
    );
  });
}