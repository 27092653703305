import { Product } from '../types';
import { formatItemNumbers } from './memoUtils';
import { getCategoryById } from './categories';

export function getCategoryText(category: string): string {
  const categoryObj = getCategoryById(category);
  return categoryObj?.label || '未設定';
}

export function getShippingMethods(product: Product): string {
  const methods = [];
  if (product.nekoposu) methods.push('ネコポス');
  if (product.nekoCompact) methods.push('宅急便コンパクト');
  if (product.nekoEazy) methods.push('宅急便');
  if (product.yuPacketMini) methods.push('ゆうパケットポストmini');
  if (product.yuPacket) methods.push('ゆうパケット');
  if (product.yuPacketPlus) methods.push('ゆうパケットプラス');
  if (product.yuPack) methods.push('ゆうパック');
  return methods.join('、') || 'なし';
}

export function getShippingOption(product: Product): string {
  if (product.shippingCOD) return '着払い';
  if (product.shippingLater) return '落札後に送料を連絡';
  if (product.shippingResponsibility === 'seller') return '送料込み';
  if (product.shippingResponsibility === 'buyer') return '落札者負担';
  return '未設定';
}

export function getConditionText(condition: string): string {
  const conditions: { [key: string]: string } = {
    'new': '未使用',
    'like-new': '未使用に近い',
    'good': '目立った傷や汚れなし',
    'fair': 'やや傷や汚れあり',
    'poor': '傷や汚れあり',
    'bad': '全体的に状態が悪い'
  };
  return conditions[condition] || '未設定';
}

export function getItemNumbers(product: Product): string[] {
  return formatItemNumbers(product);
}