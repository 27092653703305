import { useState, useCallback } from 'react';

export function useAIResponse() {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');

  const showResponse = useCallback((newMessage: string) => {
    setMessage(newMessage);
    setIsVisible(true);
  }, []);

  const hideResponse = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    isVisible,
    message,
    showResponse,
    hideResponse
  };
}