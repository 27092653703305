import React, { useState, useCallback } from 'react';
import { Trash2, Wand2 } from 'lucide-react';
import { ProductForm } from './ProductForm';
import { BulkActionBar } from './BulkActionBar';
import { CollapsibleSection } from './CollapsibleSection';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useTemplateStore } from '../stores/templateStore';
import { useProductStore } from '../stores/productStore';
import { generateTitleFromImages } from '../utils/openai';
import { AIResponsePopup } from './notifications/AIResponsePopup';
import { useAIResponse } from '../hooks/useAIResponse';
import { TitleGenerationProgress } from './bulk/TitleGenerationProgress';
import type { Product } from '../types';
import type { BulkUpdates } from '../types/bulk';

interface ProductListProps {
  products: Product[];
  onUpdate: (product: Product) => void;
  onDelete: (productId: number) => void;
  onBulkUpdate: (updates: BulkUpdates, selectedIds: number[]) => void;
}

export function ProductList({
  products,
  onUpdate,
  onDelete,
  onBulkUpdate
}: ProductListProps) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isBulkActionsOpen, setIsBulkActionsOpen] = useState(false);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const { isVisible, message, showResponse, hideResponse } = useAIResponse();
  const toggleProductSelection = useProductStore(state => state.toggleProductSelection);
  const selectAllProducts = useProductStore(state => state.selectAllProducts);
  const cancelRef = React.useRef(false);

  const selectedProducts = products.filter(p => p.isSelected);

  const handleSelectAll = useCallback((selected: boolean) => {
    selectAllProducts(selected);
  }, [selectAllProducts]);

  const handleProductSelect = useCallback((productId: number, selected: boolean) => {
    toggleProductSelection(productId);
  }, [toggleProductSelection]);

  const handleBulkDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmBulkDelete = async () => {
    await Promise.all(selectedProducts.map(p => onDelete(p.id)));
    setShowDeleteConfirmation(false);
  };

  const handleBulkUpdate = (updates: BulkUpdates) => {
    if (selectedProducts.length > 0) {
      onBulkUpdate(updates, selectedProducts.map(p => p.id));
      if (expandedProductId && selectedProducts.some(p => p.id === expandedProductId)) {
        setExpandedProductId(null);
      }
    }
  };

  const handleExpand = (productId: number) => {
    setExpandedProductId(productId === expandedProductId ? null : productId);
  };

  const handleBulkAIGenerate = async () => {
    if (isGenerating || selectedProducts.length === 0) return;

    setIsGenerating(true);
    setCurrentProductIndex(0);
    cancelRef.current = false;

    try {
      for (let i = 0; i < selectedProducts.length; i++) {
        if (cancelRef.current) break;

        setCurrentProductIndex(i);
        const product = selectedProducts[i];
        
        if (product.images.length > 0) {
          const generatedTitle = await generateTitleFromImages(product.images);
          if (generatedTitle) {
            const updatedProduct = { ...product, title: generatedTitle };
            onUpdate(updatedProduct);
            showResponse(`商品${i + 1}: ${generatedTitle}`);
          }
        }

        if (i < selectedProducts.length - 1 && !cancelRef.current) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        showResponse(error.message);
      } else {
        showResponse('タイトルの生成に失敗しました');
      }
    } finally {
      setIsGenerating(false);
      setCurrentProductIndex(0);
      cancelRef.current = false;
    }
  };

  const handleCancel = () => {
    cancelRef.current = true;
  };

  return (
    <div className="space-y-6">
      {products.length > 0 && (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="p-4 flex items-center justify-between">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={selectedProducts.length === products.length}
                onChange={(e) => handleSelectAll(e.target.checked)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">
                すべて選択 ({selectedProducts.length}/{products.length})
              </span>
            </label>
            {selectedProducts.length > 0 && (
              <div className="flex items-center gap-4">
                <button
                  onClick={handleBulkAIGenerate}
                  disabled={isGenerating}
                  className="btn-ai-compact flex items-center justify-center gap-2 px-4"
                >
                  <Wand2 className={`w-4 h-4 ${isGenerating ? 'animate-spin' : ''}`} />
                  <span>AIでまとめて生成</span>
                </button>
                <button
                  onClick={() => setIsBulkActionsOpen(!isBulkActionsOpen)}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  選択された項目を変更
                </button>
                <button
                  onClick={handleBulkDelete}
                  className="flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>選択した商品を削除</span>
                </button>
              </div>
            )}
          </div>
          {selectedProducts.length > 0 && (
            <CollapsibleSection
              title="選択された項目を変更"
              isOpen={isBulkActionsOpen}
              onToggle={() => setIsBulkActionsOpen(!isBulkActionsOpen)}
              className="border-t border-gray-200"
            >
              <BulkActionBar 
                onUpdate={handleBulkUpdate}
                selectedCount={selectedProducts.length}
              />
            </CollapsibleSection>
          )}
        </div>
      )}

      <div className="space-y-6">
        {products.map(product => (
          <ProductForm
            key={product.id}
            product={product}
            onUpdate={onUpdate}
            onDelete={onDelete}
            isSelected={product.isSelected}
            onSelect={(selected) => handleProductSelect(product.id, selected)}
            isExpanded={expandedProductId === product.id}
            onExpand={() => handleExpand(product.id)}
          />
        ))}
      </div>

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onConfirm={handleConfirmBulkDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          productTitle={`選択した${selectedProducts.length}件の商品`}
        />
      )}

      {isGenerating && (
        <TitleGenerationProgress
          currentIndex={currentProductIndex}
          totalCount={selectedProducts.length}
          onCancel={handleCancel}
        />
      )}

      <AIResponsePopup
        message={message}
        isVisible={isVisible}
        onClose={hideResponse}
      />
    </div>
  );
}