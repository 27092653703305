import React from 'react';
import { FileText, Trash2, Check } from 'lucide-react';
import { useTemplateStore } from '../../stores/templateStore';

export function TemplateList() {
  const { templates, activeTemplateId, deleteTemplate, setActiveTemplate } = useTemplateStore();

  if (templates.length === 0) {
    return (
      <div className="text-sm text-gray-500 text-center py-2">
        保存されたテンプレートはありません
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {templates.map((template) => (
        <div
          key={template.id}
          className={`flex items-center justify-between p-3 rounded-lg border ${
            activeTemplateId === template.id
              ? 'border-blue-500 bg-blue-50'
              : 'border-gray-200 hover:bg-gray-50'
          }`}
        >
          <button
            onClick={() => setActiveTemplate(activeTemplateId === template.id ? null : template.id)}
            className="flex items-center gap-3 flex-1 text-left"
          >
            <FileText className="w-5 h-5 text-gray-400" />
            <div className="flex-1">
              <h3 className="text-sm font-medium text-gray-900">{template.name}</h3>
              <p className="text-xs text-gray-500">
                最終更新: {new Date(template.updatedAt).toLocaleDateString()}
              </p>
            </div>
            {activeTemplateId === template.id && (
              <Check className="w-5 h-5 text-blue-500" />
            )}
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              deleteTemplate(template.id);
            }}
            className="p-1 text-gray-400 hover:text-red-500"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      ))}
    </div>
  );
}