import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';
import { initDB } from './db';
import { useAuthStore } from './stores/authStore';
import { useFolderStore } from './stores/folderStore';
import { useProductStore } from './stores/productStore';
import { useTemplateStore } from './stores/templateStore';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);

// Initialize database and load data
initDB()
  .then(async () => {
    const authStore = useAuthStore.getState();
    await authStore.autoLogin();

    if (authStore.isAuthenticated) {
      await Promise.all([
        useFolderStore.getState().loadFolders(),
        useProductStore.getState().loadProducts(),
        useTemplateStore.getState().loadTemplates()
      ]);
    }

    root.render(
      <StrictMode>
        <App />
      </StrictMode>
    );
  })
  .catch(error => {
    console.error('Failed to initialize application:', error);
    root.render(
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="max-w-md w-full text-center">
          <h1 className="text-2xl font-bold text-red-600 mb-4">
            エラーが発生しました
          </h1>
          <p className="text-gray-600">
            アプリケーションの初期化に失敗しました。
            <br />
            ページを再読み込みしてください。
          </p>
        </div>
      </div>
    );
  });