import { Product } from '../../types';

const BASE_TEMPLATE = `オークションをご覧いただきましてありがとうございます。


◆商品コンディション

状態ランク：{RANK}


保管品になりますので経年劣化によるスレ・汚れなどある場合がございます。
完品をお求めの方や神経質な方のご入札はご遠慮ください。

商品写真ご確認の上、お買い求め下さい。
付属品：画像に写る物が全てです（撮影用の台などの付属品は除く）


状態ランク表

◇N 未使用のお品物

◇S 数回程度の使用、非常に綺麗な未使用に近い状態のお品物

◇A 使用はしているが、良い状態のお品物

◇B 使用感はあるが、一般的に使用に問題のない状態のお品物

◇C 使用感があり傷や汚れのあるお品物

◇D 目立つ使用感やダメージがあり、使用に支障がある状態、リメイク前提のお品物


※購入後、決済期日までによろしくお願い申し上げます。

※ヤフオクガイドラインに従い、落札後のご連絡は入金後の発送連絡にて行いますので
宜しくお願い致します。


※また運送中の破損に関しまして保証可能な配送方法に限り、各社の保証規定に従います。
保証が適応の場合、落札者様からの運送会社へ連絡が必要になりますので予めご了承下さい。`;

const CONDITION_RANKS = {
  'new': 'N',
  'like-new': 'S',
  'good': 'A',
  'fair': 'B',
  'poor': 'C',
  'bad': 'D'
} as const;

export function getTemplateForCondition(product: Product, condition: string, templates: { [key: string]: string }): string {
  const savedTemplate = templates[condition];
  const defaultTemplate = getDefaultTemplate(condition);
  const baseTemplate = savedTemplate || defaultTemplate;
  
  // Add shipping methods if available
  const shippingText = getShippingMethodsText(product);
  return shippingText ? `${baseTemplate}\n\n${shippingText}` : baseTemplate;
}

function getDefaultTemplate(condition: string): string {
  const rank = CONDITION_RANKS[condition as keyof typeof CONDITION_RANKS] || '';
  return BASE_TEMPLATE.replace('{RANK}', rank);
}

function getShippingMethodsText(product: Product): string {
  const methods = [];
  if (product.nekoposu) methods.push('ネコポス');
  if (product.nekoCompact) methods.push('宅急便コンパクト');
  if (product.nekoEazy) methods.push('宅急便');
  if (product.yuPacketMini) methods.push('ゆうパケットポストmini');
  if (product.yuPacket) methods.push('ゆうパケット');
  if (product.yuPacketPlus) methods.push('ゆうパケットプラス');
  if (product.yuPack) methods.push('ゆうパック');
  
  if (methods.length === 0) return '';
  
  return `配送方法：\n${methods.map(method => `・${method}`).join('\n')}`;
}