import React from 'react';
import { ShippingMethodButton } from './ShippingMethodButton';
import type { Product } from '../../types';

interface YamatoShippingMethodsProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const YamatoShippingMethods: React.FC<YamatoShippingMethodsProps> = ({
  product,
  onChange
}) => {
  const handleSizeChange = (size: string) => {
    onChange({
      target: { name: 'nekoEazySize', value: size }
    } as React.ChangeEvent<HTMLSelectElement>);
  };

  const handleWeightChange = (weight: string) => {
    onChange({
      target: { name: 'nekoEazyWeight', value: weight }
    } as React.ChangeEvent<HTMLSelectElement>);
  };

  return (
    <div className="space-y-4">
      <h5 className="text-sm font-medium text-gray-700">ヤマト運輸</h5>
      <div className="space-y-4">
        <ShippingMethodButton
          name="nekoposu"
          label="ネコポス"
          price="全国一律 230円"
          checked={product.nekoposu}
          onChange={onChange}
        />
        <ShippingMethodButton
          name="nekoCompact"
          label="宅急便コンパクト (EAZY)"
          price="480円〜"
          checked={product.nekoCompact}
          onChange={onChange}
        />
        <ShippingMethodButton
          name="nekoEazy"
          label="宅急便 (EAZY) 60〜160サイズ"
          price="750円〜"
          checked={product.nekoEazy}
          onChange={onChange}
          showSizeSelector={true}
          size={product.nekoEazySize}
          weight={product.nekoEazyWeight}
          onSizeChange={handleSizeChange}
          onWeightChange={handleWeightChange}
          shippingLater={product.shippingLater}
        />
      </div>
    </div>
  );
};