import React, { useState } from 'react';
import { X, Save } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useProductStore } from '../../stores/productStore';
import { useTemplateStore } from '../../stores/templateStore';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { ImageGroupPreview } from './ImageGroupPreview';
import { TemplateSelector } from './TemplateSelector';
import { GroupSizeSelector } from './GroupSizeSelector';
import { UnusedImages } from './UnusedImages';
import type { ProcessingProgress } from '../../utils/imageProcessing';
import { 
  getInitialGroupSizes,
  createImageGroups,
  updateGroupSize,
  processGroupFiles,
  reorderImages,
  type ImageGroup 
} from '../../utils/imageImportUtils';
import type { ProductTemplate } from '../../utils/templates/productTemplates';

interface BulkImageEditModalProps {
  images: {
    files: File[];
    originals: string[];
    thumbnails: string[];
  };
  onClose: () => void;
  onSaved?: () => void;
}

export function BulkImageEditModal({ images, onClose, onSaved }: BulkImageEditModalProps) {
  const [showSizeSelector, setShowSizeSelector] = useState(true);
  const [imageGroups, setImageGroups] = useState<ImageGroup[]>([]);
  const [groupSizes, setGroupSizes] = useState<{ [key: string]: number }>({});
  const [defaultGroupSize, setDefaultGroupSize] = useState(4);
  const [unusedImages, setUnusedImages] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<ProcessingProgress | null>(null);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState<ProductTemplate | null>(null);
  const [deletedImages, setDeletedImages] = useState<Set<string>>(new Set());
  const [groupSettings, setGroupSettings] = useState<{
    [key: number]: {
      category?: string;
      startPrice?: number;
      condition?: string;
      shippingMethods?: string[];
      customShippingMethods?: any[];
      nekoEazySize?: string;
      nekoEazyWeight?: string;
      yuPackSize?: string;
      yuPackWeight?: string;
    };
  }>({});

  const addProduct = useProductStore(state => state.addProduct);
  const updateProduct = useProductStore(state => state.updateProduct);
  const { templates } = useTemplateStore();

  const handleSizeSelect = (size: number) => {
    const initialSizes = getInitialGroupSizes(images.files.length, size);
    setDefaultGroupSize(size);
    setGroupSizes(initialSizes);
    
    const groups = createImageGroups(
      images.files,
      images.originals,
      images.thumbnails,
      initialSizes
    );
    
    setImageGroups(groups);
    setShowSizeSelector(false);
  };

  const handleGroupSizeChange = (groupIndex: number, newSize: number) => {
    const availableImages = images.files.filter((_, index) => 
      !deletedImages.has(images.thumbnails[index])
    ).length;

    const { updatedSizes, updatedGroups } = updateGroupSize(
      images.files.filter((_, index) => !deletedImages.has(images.thumbnails[index])),
      images.originals.filter((_, index) => !deletedImages.has(images.thumbnails[index])),
      images.thumbnails.filter(thumb => !deletedImages.has(thumb)),
      groupSizes,
      groupIndex,
      newSize
    );

    setGroupSizes(updatedSizes);
    setImageGroups(updatedGroups);
  };

  const handleDeleteImage = (groupIndex: number, imageIndex: number) => {
    const updatedGroups = [...imageGroups];
    const group = updatedGroups[groupIndex];
    
    if (group) {
      const deletedThumbnail = group.thumbnails[imageIndex];
      setDeletedImages(prev => new Set([...prev, deletedThumbnail]));

      group.files.splice(imageIndex, 1);
      group.originals.splice(imageIndex, 1);
      group.thumbnails.splice(imageIndex, 1);

      const newGroupSizes = { ...groupSizes };
      newGroupSizes[groupIndex] = Math.max(1, group.files.length);

      setImageGroups(updatedGroups);
      setGroupSizes(newGroupSizes);
    }
  };

  const handleReorderImages = (groupIndex: number, fromIndex: number, toIndex: number) => {
    setImageGroups(prev => {
      const newGroups = [...prev];
      newGroups[groupIndex] = reorderImages(newGroups[groupIndex], fromIndex, toIndex);
      return newGroups;
    });
  };

  const handleSettingsChange = (groupIndex: number, updates: any) => {
    setGroupSettings(prev => ({
      ...prev,
      [groupIndex]: {
        ...prev[groupIndex],
        ...updates
      }
    }));
  };

  const handleCreateProducts = async () => {
    if (imageGroups.length === 0 || isProcessing) return;

    setIsProcessing(true);
    setError(null);
    setCurrentProductIndex(0);

    try {
      for (let i = 0; i < imageGroups.length; i++) {
        setCurrentProductIndex(i);
        const group = imageGroups[i];
        const settings = groupSettings[i] || {};
        
        if (group.files.length > 0) {
          setProgress({
            current: i + 1,
            total: imageGroups.length,
            phase: 'processing'
          });

          const processedFiles = await Promise.all(
            group.files.map(async file => {
              const formData = new FormData();
              formData.append('file', file);
              return file;
            })
          );

          const { originals } = await processGroupFiles(processedFiles);
          
          if (originals.length > 0) {
            const newProduct = await addProduct(selectedTemplate !== null);
            
            const baseProduct = selectedTemplate?.fields 
              ? {
                  ...newProduct,
                  ...selectedTemplate.fields,
                  customShippingMethods: [...selectedTemplate.fields.customShippingMethods]
                }
              : newProduct;

            const updatedProduct = {
              ...baseProduct,
              images: originals,
              ...(settings.category && { category: settings.category }),
              ...(settings.startPrice && { startPrice: settings.startPrice }),
              ...(settings.condition && { condition: settings.condition }),
              ...(settings.shippingMethods && {
                nekoposu: settings.shippingMethods.includes('nekoposu'),
                nekoCompact: settings.shippingMethods.includes('nekoCompact'),
                nekoEazy: settings.shippingMethods.includes('nekoEazy'),
                yuPacketMini: settings.shippingMethods.includes('yuPacketMini'),
                yuPacket: settings.shippingMethods.includes('yuPacket'),
                yuPacketPlus: settings.shippingMethods.includes('yuPacketPlus'),
                yuPack: settings.shippingMethods.includes('yuPack')
              }),
              ...(settings.customShippingMethods?.length > 0 && {
                customShippingMethods: settings.customShippingMethods
              }),
              ...(settings.nekoEazySize && { nekoEazySize: settings.nekoEazySize }),
              ...(settings.nekoEazyWeight && { nekoEazyWeight: settings.nekoEazyWeight }),
              ...(settings.yuPackSize && { yuPackSize: settings.yuPackSize }),
              ...(settings.yuPackWeight && { yuPackWeight: settings.yuPackWeight })
            };

            await updateProduct(updatedProduct);
          }
        }
      }

      onClose();
      if (onSaved) onSaved();
    } catch (error) {
      console.error('Error creating products:', error);
      setError('商品の作成中にエラーが発生しました');
    } finally {
      setIsProcessing(false);
      setProgress(null);
      setCurrentProductIndex(0);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] flex flex-col">
        <div className="p-4 border-b flex items-center justify-between">
          <h2 className="text-xl font-medium text-gray-900">写真のグループ分け</h2>
          {!showSizeSelector && (
            <div className="flex items-center gap-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                キャンセル
              </button>
              <button
                onClick={handleCreateProducts}
                disabled={imageGroups.length === 0 || isProcessing}
                className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 rounded-md disabled:from-gray-400 disabled:to-gray-500 disabled:cursor-not-allowed"
              >
                商品を作成
              </button>
            </div>
          )}
        </div>
        
        <div className="flex-1 overflow-y-auto p-6">
          {showSizeSelector ? (
            <GroupSizeSelector
              onSelect={handleSizeSelect}
              onClose={onClose}
              defaultSize={defaultGroupSize}
              totalImages={images.files.length}
            />
          ) : (
            <div className="space-y-6">
              {templates.length > 0 && (
                <div className="flex items-center gap-4 bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50 p-6 rounded-lg shadow-sm">
                  <span className="text-lg font-medium text-gray-800">テンプレートを選択:</span>
                  <div className="flex-1">
                    <TemplateSelector
                      templates={templates}
                      selectedTemplate={selectedTemplate}
                      onSelect={setSelectedTemplate}
                    />
                  </div>
                </div>
              )}

              <div className="space-y-8">
                {imageGroups.map((group, index) => (
                  <ImageGroupPreview
                    key={`${group.prefix}-${index}`}
                    group={group}
                    groupSize={groupSizes[index] || defaultGroupSize}
                    onGroupSizeChange={(size) => handleGroupSizeChange(index, size)}
                    index={index}
                    totalImages={images.files.length - deletedImages.size}
                    onDeleteImage={handleDeleteImage}
                    onReorderImages={handleReorderImages}
                    onSettingsChange={handleSettingsChange}
                    category={groupSettings[index]?.category || ''}
                    startPrice={groupSettings[index]?.startPrice || 0}
                    condition={groupSettings[index]?.condition || ''}
                    shippingMethods={groupSettings[index]?.shippingMethods}
                    customShippingMethods={groupSettings[index]?.customShippingMethods}
                    nekoEazySize={groupSettings[index]?.nekoEazySize}
                    nekoEazyWeight={groupSettings[index]?.nekoEazyWeight}
                    yuPackSize={groupSettings[index]?.yuPackSize}
                    yuPackWeight={groupSettings[index]?.yuPackWeight}
                  />
                ))}
              </div>

              {unusedImages.length > 0 && (
                <UnusedImages
                  images={unusedImages}
                  onDelete={(index) => {
                    setUnusedImages(prev => prev.filter((_, i) => i !== index));
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {isProcessing && (
        <LoadingOverlay 
          message="商品を作成してインポート中です。今しばらくお待ちください..."
          progress={progress}
          subMessage={
            imageGroups.length > 0
              ? `商品 ${currentProductIndex + 1}/${imageGroups.length} を処理中...`
              : undefined
          }
        />
      )}
    </div>
  );
}