import React from 'react';
import { SizeSelector } from './SizeSelector';

interface ShippingMethodButtonProps {
  name: string;
  label: string;
  price: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showSizeSelector?: boolean;
  size?: string;
  weight?: string;
  onSizeChange?: (size: string) => void;
  onWeightChange?: (weight: string) => void;
  shippingLater?: boolean;
}

export const ShippingMethodButton: React.FC<ShippingMethodButtonProps> = ({
  name,
  label,
  price,
  checked,
  onChange,
  showSizeSelector = false,
  size = '',
  weight = '',
  onSizeChange,
  onWeightChange,
  shippingLater = false
}) => (
  <div className="space-y-2">
    <label className={`
      flex items-center justify-between p-3 rounded-lg border-2 cursor-pointer transition-colors duration-200
      ${checked ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:bg-gray-50'}
    `}>
      <div className="flex items-center">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <span className="ml-3 font-medium text-gray-900">{label}</span>
      </div>
      <span className="text-gray-600">{price}</span>
    </label>

    {checked && showSizeSelector && !shippingLater && (
      <SizeSelector
        name={name}
        size={size}
        weight={weight}
        onChange={(e) => {
          const { name, value } = e.target;
          if (name.endsWith('Size') && onSizeChange) {
            onSizeChange(value);
          } else if (name.endsWith('Weight') && onWeightChange) {
            onWeightChange(value);
          }
        }}
      />
    )}
  </div>
);