import type { Product } from '../../types';
import { getSizeValue, getSizeLabel } from './packageSizes';

interface PackageSize {
  value: number;
  source: 'yamato' | 'japan-post';
}

interface PackageWeight {
  weight: number;
  source: 'yamato' | 'japan-post';
}

// Get the largest package size between Yamato and Japan Post
export function getLargestPackageSize(product: Product): PackageSize {
  const yamatoSizeValue = getSizeValue(product.nekoEazySize);
  const japanPostSizeValue = getSizeValue(product.yuPackSize);

  if (yamatoSizeValue >= japanPostSizeValue) {
    return { value: yamatoSizeValue, source: 'yamato' };
  } else {
    return { value: japanPostSizeValue, source: 'japan-post' };
  }
}

// Get the largest package weight between Yamato and Japan Post
export function getLargestPackageWeight(product: Product): PackageWeight {
  const yamatoWeight = parseInt(product.nekoEazyWeight) || 0;
  const japanPostWeight = parseInt(product.yuPackWeight) || 0;

  if (yamatoWeight >= japanPostWeight) {
    return { weight: yamatoWeight, source: 'yamato' };
  } else {
    return { weight: japanPostWeight, source: 'japan-post' };
  }
}

// Format size according to predefined ranges
export function formatPackageSize(size: PackageSize): string {
  return getSizeLabel(size.value);
}

// Format weight according to predefined ranges
export function formatPackageWeight(weight: number): string {
  if (weight <= 2) return '～2kg';
  if (weight <= 5) return '～5kg';
  if (weight <= 10) return '～10kg';
  if (weight <= 15) return '～15kg';
  if (weight <= 20) return '～20kg';
  if (weight <= 25) return '～25kg';
  return '～30kg';
}