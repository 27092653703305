import React from 'react';
import type { Product } from '../../types';

interface BasicSettingsProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

export const BasicSettings: React.FC<BasicSettingsProps> = ({ product, onChange }) => {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">基本設定</h4>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">支払いから発送までの日数</label>
          <select
            name="shippingTime"
            value={product.shippingTime}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
          >
            <option value="">選択してください</option>
            <option value="1-2">1～2日</option>
            <option value="2-3">2～3日</option>
            <option value="3-7">3～7日</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">個数</label>
          <input
            type="number"
            name="quantity"
            value={product.quantity}
            onChange={onChange}
            min="1"
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">開催期間</label>
          <select
            name="auctionDuration"
            value={product.auctionDuration}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
          >
            {[1, 2, 3, 4, 5, 6, 7].map(days => (
              <option key={days} value={days}>{days}日</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">終了時間</label>
          <select
            name="endTime"
            value={product.endTime}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
          >
            {Array.from({ length: 24 }, (_, i) => (
              <option key={i} value={i}>{i}時</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};