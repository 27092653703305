export const DB_NAME = 'yahoo-auction-tool';
export const DB_VERSION = 12;

export const STORES = {
  PRODUCTS: 'products',
  IMAGES: 'images',
  FOLDERS: 'folders',
  USERS: 'users',
  TEMPLATES: 'templates'
} as const;

export const INDEXES = {
  USER_SERIAL_NUMBER: 'userSerialNumber',
  FOLDER_ID: 'folderId',
  USER_ID: 'userId',
  CREATED_AT: 'createdAt',
  EMAIL: 'email',
  PRODUCT_ID: 'productId'
} as const;

export const DB_CONFIG = {
  TRANSACTION_TIMEOUT: 5000, // 5 seconds
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000 // 1 second
};