import React from 'react';

interface FeaturedAuctionSettingsProps {
  isFeatured: boolean;
  featuredMinPrice: number;
  featuredMaxPrice: number;
  recommendedCollection: boolean;
  recommendedCollectionRate: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FeaturedAuctionSettings: React.FC<FeaturedAuctionSettingsProps> = ({
  isFeatured = false,
  featuredMinPrice = 0,
  featuredMaxPrice = 0,
  recommendedCollection = false,
  recommendedCollectionRate = 0,
  onChange
}) => {
  const handleFeaturedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onChange(e);
    
    if (!isChecked) {
      // Clear price when unchecking
      onChange({
        target: {
          name: 'featuredMinPrice',
          value: '0',
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleRecommendedCollectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onChange(e);
    
    if (!isChecked) {
      // Clear rate when unchecking
      onChange({
        target: {
          name: 'recommendedCollectionRate',
          value: '0',
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    // Allow empty input for better UX
    if (value === '') {
      onChange({
        target: {
          name: 'featuredMinPrice',
          value: '0',
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
      return;
    }

    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      onChange({
        target: {
          name: 'featuredMinPrice',
          value: numValue.toString(),
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    
    if (value === '') {
      onChange({
        target: {
          name: 'recommendedCollectionRate',
          value: '0',
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
      return;
    }

    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      onChange({
        target: {
          name: 'recommendedCollectionRate',
          value: numValue.toString(),
          type: 'number'
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const isValidPrice = featuredMinPrice >= 20 && featuredMinPrice <= 999;
  const showPriceError = featuredMinPrice !== 0 && !isValidPrice;

  const isValidRate = recommendedCollectionRate >= 0 && recommendedCollectionRate <= 100;
  const showRateError = recommendedCollectionRate !== 0 && !isValidRate;

  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <div className="space-y-4">
        {/* Featured Auction */}
        <div>
          <div className="flex items-center justify-between mb-4">
            <h4 className="font-medium">注目のオークション</h4>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="isFeatured"
                checked={isFeatured}
                onChange={handleFeaturedChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">注目のオークションに設定する</span>
            </label>
          </div>

          {isFeatured && (
            <div>
              <label className="block text-sm font-medium text-gray-700">金額</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  name="featuredMinPrice"
                  value={featuredMinPrice === 0 ? '' : featuredMinPrice}
                  onChange={handlePriceChange}
                  min="20"
                  max="999"
                  placeholder="20～999"
                  className={`block w-full rounded-md pr-12 ${
                    showPriceError 
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                      : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                  } bg-gray-50`}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm">円</span>
                </div>
              </div>
              {showPriceError && (
                <p className="mt-1 text-sm text-red-600">
                  20～999の範囲で入力してください
                </p>
              )}
            </div>
          )}
        </div>

        {/* Recommended Collection */}
        <div>
          <div className="flex items-center justify-between mb-4">
            <h4 className="font-medium">おすすめコレクション</h4>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="recommendedCollection"
                checked={recommendedCollection}
                onChange={handleRecommendedCollectionChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-700">おすすめコレクションに設定する</span>
            </label>
          </div>

          {recommendedCollection && (
            <div>
              <label className="block text-sm font-medium text-gray-700">表示確率</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  name="recommendedCollectionRate"
                  value={recommendedCollectionRate === 0 ? '' : recommendedCollectionRate}
                  onChange={handleRateChange}
                  min="0"
                  max="100"
                  placeholder="0～100"
                  className={`block w-full rounded-md pr-12 ${
                    showRateError 
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                      : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                  } bg-gray-50`}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-gray-500 sm:text-sm">%</span>
                </div>
              </div>
              {showRateError && (
                <p className="mt-1 text-sm text-red-600">
                  0～100の範囲で入力してください
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};