import { openDB } from 'idb';
import { DB_NAME, DB_VERSION } from '../config';
import type { IDBPDatabase, IDBPTransaction } from 'idb';

let dbInstance: IDBPDatabase | null = null;

export async function getDatabase(): Promise<IDBPDatabase> {
  if (dbInstance) return dbInstance;

  try {
    dbInstance = await openDB(DB_NAME, DB_VERSION);
    return dbInstance;
  } catch (error) {
    console.error('Failed to open database:', error);
    throw new Error('データベースの接続に失敗しました');
  }
}

export async function executeTransaction<T>(
  storeNames: string | string[],
  mode: 'readonly' | 'readwrite',
  operation: (tx: IDBPTransaction<unknown, string[], 'readonly' | 'readwrite'>) => Promise<T>
): Promise<T> {
  const db = await getDatabase();
  const stores = Array.isArray(storeNames) ? storeNames : [storeNames];
  const tx = db.transaction(stores, mode, {
    durability: 'strict'
  });
  
  try {
    const result = await operation(tx);
    await tx.done;
    return result;
  } catch (error) {
    console.error('Transaction error:', error);
    await tx.abort();
    throw error;
  }
}

export function closeDatabase(): void {
  if (dbInstance) {
    dbInstance.close();
    dbInstance = null;
  }
}

// Handle connection errors and version changes
window.addEventListener('unload', closeDatabase);