import React from 'react';

interface ItemNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

export function ItemNumberInput({ value, onChange, label = "管理番号" }: ItemNumberInputProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          type="text"
          value={value}
          onChange={handleInputChange}
          className="block w-full py-2 px-3 sm:text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
          placeholder={`${label}を入力`}
        />
      </div>
    </div>
  );
}