import { STORES, INDEXES } from '../config';
import { executeTransaction } from './baseOperations';

export async function saveProductImage(productId: number, imageData: string): Promise<void> {
  return executeTransaction(STORES.IMAGES, 'readwrite', async (tx) => {
    await tx.objectStore(STORES.IMAGES).add({
      productId,
      data: imageData,
      createdAt: new Date().toISOString()
    });
  });
}

export async function getProductImages(productId: number): Promise<string[]> {
  return executeTransaction(STORES.IMAGES, 'readonly', async (tx) => {
    const images = await tx.objectStore(STORES.IMAGES)
      .index(INDEXES.PRODUCT_ID)
      .getAll(productId);
    
    return images.map(img => img.data);
  });
}

export async function deleteProductImages(productId: number): Promise<void> {
  return executeTransaction(STORES.IMAGES, 'readwrite', async (tx) => {
    const store = tx.objectStore(STORES.IMAGES);
    const imageKeys = await store.index(INDEXES.PRODUCT_ID).getAllKeys(productId);
    await Promise.all(imageKeys.map(key => store.delete(key)));
  });
}