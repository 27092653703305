import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wand2, X } from 'lucide-react';

interface AIResponsePopupProps {
  message: string;
  isVisible: boolean;
  onClose: () => void;
  autoCloseDelay?: number;
}

export function AIResponsePopup({ 
  message, 
  isVisible, 
  onClose,
  autoCloseDelay = 5000
}: AIResponsePopupProps) {
  useEffect(() => {
    if (isVisible && autoCloseDelay > 0) {
      const timer = setTimeout(onClose, autoCloseDelay);
      return () => clearTimeout(timer);
    }
  }, [isVisible, autoCloseDelay, onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="fixed top-4 right-4 z-50 max-w-md w-full"
        >
          <div className="bg-white rounded-lg shadow-lg border border-purple-200 p-4">
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0 p-2 bg-purple-100 rounded-full">
                <Wand2 className="w-5 h-5 text-purple-600" />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm text-gray-700">{message}</p>
              </div>
              <button
                onClick={onClose}
                className="flex-shrink-0 p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 transition-colors"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}