import { Product } from '../types';

export function createTitleWithItemNumbers(product: Product): string {
  // Only process the title text itself, without item numbers
  let title = product.title || '';
  
  // Replace memo placeholders
  if (product.memo && title.includes('*')) {
    title = title.replace(/\*/g, product.memo);
  }

  // Remove any existing item numbers from the title
  title = title.replace(/^\d+[A-Za-z0-9-_]+\s+/, ''); // Remove from start
  title = title.replace(/\s+\d+[A-Za-z0-9-_]+$/, ''); // Remove from end

  return title.trim();
}