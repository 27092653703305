import React, { useState, useRef, useEffect } from 'react';
import { StickyNote, AlertCircle } from 'lucide-react';
import { calculateCharacterCount, MAX_TOTAL_LENGTH } from '../utils/characterCount';
import type { Product } from '../types';

interface MemoInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick?: (e: React.MouseEvent) => void;
  product: Product;
}

export function MemoInput({ value, onChange, onClick, product }: MemoInputProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const validateMemoChange = (newMemo: string): boolean => {
    const testProduct = {
      ...product,
      memo: newMemo
    };
    const charCount = calculateCharacterCount(testProduct);
    return charCount.isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (validateMemoChange(newValue)) {
      setEditedValue(newValue);
      setError(null);
    } else {
      setError('メモを置換すると文字数制限を超えてしまいます');
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editedValue !== value && !error) {
      onChange(editedValue);
    } else {
      setEditedValue(value);
    }
    setError(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleBlur();
    }
    if (e.key === 'Escape') {
      setEditedValue(value);
      setIsEditing(false);
      setError(null);
    }
  };

  if (isEditing) {
    return (
      <div className="space-y-2">
        <textarea
          ref={inputRef}
          value={editedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={`w-full text-sm text-gray-600 border rounded p-2 focus:outline-none focus:ring-2 resize-none ${
            error ? 'border-red-300 focus:ring-red-500' : 'border-blue-500 focus:ring-blue-500'
          }`}
          rows={2}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) onClick(e);
          }}
        />
        {error && (
          <div className="flex items-center gap-2 text-sm text-red-600">
            <AlertCircle className="w-4 h-4" />
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) onClick(e);
        setIsEditing(true);
      }}
      className="w-full flex items-start gap-2 text-left hover:bg-gray-50 p-2 rounded-md transition-colors"
    >
      <StickyNote className="w-4 h-4 text-green-500 flex-shrink-0 mt-0.5" />
      <span className="text-sm text-gray-600 line-clamp-2">
        {value || 'メモを追加'}
      </span>
    </button>
  );
}