import type { Product } from '../types';

export function replaceMemoPlaceholders(text: string, memo: string): string {
  if (!text) return '';
  if (!memo) return text.replace(/\*/g, '');
  return text.replace(/\*/g, memo);
}

export function getItemNumberWithMemo(itemNumber: string, memo: string, useSerial: boolean, serialNumber?: number, appendSerial: boolean = false): string {
  if (!itemNumber) return '';
  
  let processedNumber = itemNumber;
  
  // Replace memo placeholders if present
  processedNumber = replaceMemoPlaceholders(processedNumber, memo);
  
  // Add serial number if enabled
  if (useSerial && serialNumber !== undefined) {
    processedNumber = appendSerial ? `${processedNumber}${serialNumber}` : `${serialNumber}${processedNumber}`;
  }
  
  return processedNumber;
}

export function formatItemNumbers(product: Product): string[] {
  const numbers: string[] = [];
  
  // Process first item number
  if (product.itemNumber && (product.itemNumberPrefix || product.itemNumberSuffix)) {
    const number1 = getItemNumberWithMemo(
      product.itemNumber,
      product.memo,
      product.itemNumberSerial,
      product.serialNumber
    );
    if (number1) numbers.push(number1);
  }
  
  // Process second item number independently
  if (product.itemNumber2 && (product.memoPrefix || product.memoSuffix)) {
    const number2 = getItemNumberWithMemo(
      product.itemNumber2,
      product.memo,
      product.memoSerial,
      product.serialNumber,
      true // Append serial number at the end for itemNumber2
    );
    if (number2) numbers.push(number2);
  }
  
  return numbers;
}

export function createDisplayTitle(product: Product): string {
  const { title, memo } = product;
  
  // Replace memo placeholders in title
  const processedTitle = replaceMemoPlaceholders(title, memo);
  
  // Build final title with correct positioning
  const parts: string[] = [];
  
  // Add first item number if prefix is enabled
  if (product.itemNumberPrefix && product.itemNumber) {
    const number1 = getItemNumberWithMemo(
      product.itemNumber,
      product.memo,
      product.itemNumberSerial,
      product.serialNumber
    );
    if (number1) parts.push(number1);
  }
  
  // Add second item number if prefix is enabled (independently)
  if (product.memoPrefix && product.itemNumber2) {
    const number2 = getItemNumberWithMemo(
      product.itemNumber2,
      product.memo,
      product.memoSerial,
      product.serialNumber,
      true // Append serial number at the end for itemNumber2
    );
    if (number2) parts.push(number2);
  }
  
  // Add main title
  if (processedTitle) {
    parts.push(processedTitle);
  }
  
  // Add first item number if suffix is enabled
  if (product.itemNumberSuffix && product.itemNumber) {
    const number1 = getItemNumberWithMemo(
      product.itemNumber,
      product.memo,
      product.itemNumberSerial,
      product.serialNumber
    );
    if (number1) parts.push(number1);
  }
  
  // Add second item number if suffix is enabled (independently)
  if (product.memoSuffix && product.itemNumber2) {
    const number2 = getItemNumberWithMemo(
      product.itemNumber2,
      product.memo,
      product.memoSerial,
      product.serialNumber,
      true // Append serial number at the end for itemNumber2
    );
    if (number2) parts.push(number2);
  }
  
  return parts.join(' ').trim();
}