import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { AuthState } from '../types/auth';
import { createUser, getUserByEmail, updateUser } from '../db';

const DEFAULT_USER = {
  email: 'default@example.com',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  lastLoginAt: new Date().toISOString(),
  settings: {
    defaultListingType: 'auction',
    defaultAuctionDuration: 7,
    defaultEndTime: '22',
    defaultSerialNumber: 0
  }
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,

      autoLogin: async () => {
        try {
          let user = await getUserByEmail(DEFAULT_USER.email);
          
          if (!user) {
            user = DEFAULT_USER;
            await createUser(user);
          } else {
            // Update last login time
            const updatedUser = {
              ...user,
              lastLoginAt: new Date().toISOString()
            };
            await updateUser(user.email, updatedUser);
            user = updatedUser;
          }

          set({
            user,
            isAuthenticated: true
          });
        } catch (error) {
          console.error('Auto login error:', error);
          throw error;
        }
      },

      logout: () => {
        set({
          user: null,
          isAuthenticated: false
        });
      },

      updateUserSettings: async (settings) => {
        const { user } = get();
        if (!user) return;

        try {
          const updatedUser = {
            ...user,
            settings: {
              ...user.settings,
              ...settings
            },
            updatedAt: new Date().toISOString()
          };

          await updateUser(user.email, updatedUser);
          set({ user: updatedUser });
        } catch (error) {
          console.error('Error updating user settings:', error);
          throw new Error('設定の更新に失敗しました');
        }
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated
      })
    }
  )
);