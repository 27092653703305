export class OpenAIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'OpenAIError';
  }
}

export function handleOpenAIError(error: any): never {
  console.error('OpenAI API Error:', error);

  // Handle specific API errors
  if (error?.error?.type === 'invalid_request_error') {
    if (error?.error?.message?.includes('model')) {
      throw new OpenAIError('申し訳ありませんが、現在画像解析サービスをご利用いただけません。システムの更新をお待ちください。');
    }
    if (error?.error?.message?.includes('size')) {
      throw new OpenAIError('画像サイズが大きすぎます。より小さい画像を使用してください。');
    }
    if (error?.error?.message?.includes('deprecated')) {
      throw new OpenAIError('申し訳ありませんが、現在システムのアップデート中です。しばらくお待ちください。');
    }
    if (error?.error?.message?.includes('quota')) {
      throw new OpenAIError('APIの利用制限に達しました。しばらく待ってから再度お試しください。');
    }
    if (error?.error?.message?.includes('content type')) {
      throw new OpenAIError('画像の形式が正しくありません。別の画像を試してください。');
    }
    if (error?.error?.message?.includes('Invalid content type')) {
      throw new OpenAIError('申し訳ありませんが、現在画像解析サービスをご利用いただけません。システムの更新をお待ちください。');
    }
    throw new OpenAIError('画像の解析に失敗しました。別の画像を試してください。');
  }

  // Handle rate limits
  if (error?.error?.code === 'rate_limit_exceeded') {
    throw new OpenAIError('APIの利用制限に達しました。しばらく待ってから再度お試しください。');
  }

  // Handle authentication errors
  if (error?.error?.code === 'invalid_api_key') {
    throw new OpenAIError('APIキーが無効です。システム管理者に連絡してください。');
  }

  // Handle context length errors
  if (error?.error?.code === 'context_length_exceeded') {
    throw new OpenAIError('画像が多すぎます。より少ない枚数で試してください。');
  }

  // Handle timeouts
  if (error?.error?.code === 'timeout') {
    throw new OpenAIError('応答がタイムアウトしました。再度お試しください。');
  }

  // Handle quota errors
  if (error?.error?.code === 'insufficient_quota') {
    throw new OpenAIError('APIの利用制限に達しました。システム管理者に連絡してください。');
  }

  // Default error
  throw new OpenAIError('タイトルの生成に失敗しました。しばらく待ってから再度お試しください。');
}