import React from 'react';
import { Tag, Gavel } from 'lucide-react';

interface PriceDisplayProps {
  listingType: 'auction' | 'fixed';
  startPrice: number;
  buyItNowPrice: number;
  className?: string;
}

export function PriceDisplay({ listingType, startPrice, buyItNowPrice, className = '' }: PriceDisplayProps) {
  return (
    <div className={`flex items-center gap-4 ${className}`}>
      <div className="flex items-center gap-2">
        {listingType === 'auction' ? (
          <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
            <Gavel className="w-4 h-4" />
            オークション
          </span>
        ) : (
          <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-red-50 text-red-600">
            <Tag className="w-4 h-4" />
            定額
          </span>
        )}
      </div>
      <div className="flex items-center gap-2">
        <div className="text-sm">
          <span className="text-gray-600">
            {listingType === 'auction' ? '開始価格: ' : '販売価格: '}
          </span>
          <span className="font-medium text-gray-900">
            {startPrice.toLocaleString()}円
          </span>
        </div>
        {listingType === 'auction' && buyItNowPrice > 0 && (
          <div className="text-sm">
            <span className="text-gray-600">即決価格: </span>
            <span className="font-medium text-gray-900">
              {buyItNowPrice.toLocaleString()}円
            </span>
          </div>
        )}
      </div>
    </div>
  );
}