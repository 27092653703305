import React from 'react';
import { Plus } from 'lucide-react';
import { CustomShippingMethod } from './CustomShippingMethod';
import type { Product } from '../../types';

interface CustomShippingMethodListProps {
  product: Product;
  showPrices: boolean;
  onCustomMethodAdd: () => void;
  onCustomMethodUpdate: (index: number, method: any) => void;
  onCustomMethodRemove: (index: number) => void;
}

export const CustomShippingMethodList: React.FC<CustomShippingMethodListProps> = ({
  product,
  showPrices,
  onCustomMethodAdd,
  onCustomMethodUpdate,
  onCustomMethodRemove
}) => {
  const canAddMore = product.customShippingMethods.length < 10;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h5 className="text-sm font-medium text-gray-700">
          {product.shippingCOD ? '着払い配送方法' : 'その他の配送方法'}
          <span className="ml-2 text-sm text-gray-500">
            ({product.customShippingMethods.length}/10)
          </span>
        </h5>
        {canAddMore && (
          <button
            onClick={onCustomMethodAdd}
            className="flex items-center gap-1 px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>{product.shippingCOD ? '着払い配送方法を追加' : '配送方法を追加'}</span>
          </button>
        )}
      </div>
      <div className="space-y-4">
        {product.customShippingMethods.map((method, index) => (
          <CustomShippingMethod
            key={index}
            method={method}
            onUpdate={(updatedMethod) => onCustomMethodUpdate(index, updatedMethod)}
            onRemove={() => onCustomMethodRemove(index)}
            showPrices={showPrices}
            shippingResponsibility={product.shippingResponsibility}
            shippingLater={product.shippingLater}
            shippingCOD={product.shippingCOD}
          />
        ))}
      </div>
    </div>
  );
};