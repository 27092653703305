import { openai } from './openai/client';
import { handleOpenAIError } from './openai/errors';
import { cleanTitle } from './openai/titleUtils';
import { OPENAI_CONFIG, PROMPT_TEMPLATES } from './openai/config';
import { withRetry } from './openai/retry';
import type { ImageAnalysisMessage } from './openai/types';

export async function generateTitleFromImages(images: string[]): Promise<string> {
  try {
    const imagesToAnalyze = images.slice(0, OPENAI_CONFIG.maxImages);
    
    const message: ImageAnalysisMessage = {
      role: "user",
      content: [
        {
          type: "text",
          text: PROMPT_TEMPLATES.titleGeneration
        },
        ...imagesToAnalyze.map(imageUrl => ({
          type: "image_url",
          image_url: {
            url: imageUrl,
            detail: "high"
          }
        }))
      ]
    };

    const response = await withRetry(
      () => openai.chat.completions.create({
        model: OPENAI_CONFIG.model,
        messages: [message],
        max_tokens: OPENAI_CONFIG.maxTokens,
        temperature: OPENAI_CONFIG.temperature,
        top_p: OPENAI_CONFIG.topP,
        frequency_penalty: OPENAI_CONFIG.frequencyPenalty,
        presence_penalty: OPENAI_CONFIG.presencePenalty
      }),
      {
        maxRetries: OPENAI_CONFIG.maxRetries,
        initialDelay: OPENAI_CONFIG.retryDelay
      }
    );

    const title = response.choices[0]?.message?.content;
    if (!title) {
      throw new Error('タイトルを生成できませんでした');
    }

    return cleanTitle(title);
  } catch (error) {
    throw handleOpenAIError(error);
  }
}