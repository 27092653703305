import { Product } from '../../types';
import { CSVRow } from '../../types/csv';
import { formatPackageSize, formatPackageWeight, getLargestPackageSize, getLargestPackageWeight } from '../shipping/packageCalculations';
import { formatYesNo } from './formatters/yesNo';
import { formatCondition } from './formatters/condition';
import { formatReturns } from './formatters/returns';
import { formatShippingPayer, formatShippingMethods } from './formatters/shipping';
import { formatShippingTime } from '../shipping/formats';
import { getCategoryById } from '../categories';
import { getDisplayTitle } from '../titleProcessor';

let currentImageNumber = 1;

export function resetImageCounter(): void {
  currentImageNumber = 1;
}

export function formatProductRow(product: Product): CSVRow {
  const category = getCategoryById(product.category);
  const largestSize = getLargestPackageSize(product);
  const largestWeight = getLargestPackageWeight(product);
  
  const row: CSVRow = [
    category?.yahooId || '', // カテゴリID
    getDisplayTitle(product), // Use getDisplayTitle to include management numbers
    product.description,
    product.startPrice,
    product.buyItNowPrice || '',
    product.quantity,
    product.auctionDuration,
    product.endTime,
    '', // JANコード
  ];

  // Add image fields (10 sets)
  for (let i = 0; i < 10; i++) {
    if (i < product.images.length) {
      row.push(`${String(currentImageNumber++).padStart(6, '0')}.jpg`, '');
    } else {
      row.push('', '');
    }
  }

  // Add remaining fields
  row.push(
    product.shippingLocation,
    '', // 市区町村
    formatShippingPayer(product.shippingResponsibility),
    '先払い',
    formatYesNo(true), // かんたん決済
    formatYesNo(true), // かんたん取引
    formatYesNo(false), // 商品代引
    formatCondition(product.condition),
    '', // 商品の状態備考
    formatReturns(product.returnsAccepted),
    '', // 返品の可否備考
    formatYesNo(product.ratingRestriction),
    formatYesNo(product.badRatingRestriction),
    formatYesNo(product.bidderAuthentication),
    formatYesNo(product.autoExtensionByBid),
    formatYesNo(true), // 早期終了
    formatYesNo(false), // 値下げ交渉
    product.autoExtension ? product.autoExtensionCount.toString() : '0',
    formatYesNo(product.autoExtension),
    product.autoExtension ? product.autoExtensionRate.toString() : '',
    product.isFeatured ? product.featuredMinPrice.toString() : '',
    product.recommendedCollection ? product.recommendedCollectionRate.toString() : '',
    formatYesNo(!product.shippingLater), // 送料の固定 - 送料を後で連絡する場合は「いいえ」
    formatPackageSize(largestSize),
    formatPackageWeight(largestWeight.weight),
    formatYesNo(product.nekoposu),
    formatYesNo(product.nekoCompact),
    formatYesNo(product.nekoEazy),
    formatYesNo(product.yuPacket),
    formatYesNo(product.yuPack),
    formatYesNo(product.yuPacketMini),
    formatYesNo(product.yuPacketPlus),
    formatShippingTime(product.shippingTime)
  );

  // Add shipping method fields (10 sets of 5 fields each)
  const shippingMethodFields = formatShippingMethods(product);
  row.push(...shippingMethodFields);

  // Add final fields
  row.push(
    formatYesNo(false), // 受け取り後決済サービス
    formatYesNo(false)  // 海外発送
  );

  return row;
}