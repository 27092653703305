export interface Category {
  id: string;
  label: string;
  yahooId: number;
}

export const CATEGORIES: Category[] = [
  { id: 'toys', label: 'おもちゃ・ゲーム', yahooId: 26082 },
  { id: 'cd', label: 'CD', yahooId: 22240 },
  { id: 'dvd', label: 'DVD', yahooId: 22016 },
  { id: 'a-dvd', label: 'アダルトDVD', yahooId: 2084230642 },
  { id: 'a-other', label: 'アダルトその他', yahooId: 26174 },
  { id: 'blu-ray', label: 'ブルーレイ', yahooId: 2084249094 },
  { id: 'cassette', label: 'カセットテープ', yahooId: 22392 },
  { id: 'classic-cd', label: 'クラシックCD', yahooId: 2084006958 },
  { id: 'classic-dvd', label: 'クラシックDVD', yahooId: 2084049856 },
  { id: 'computer', label: 'コンピュータ', yahooId: 23564 },
  { id: 'electronics', label: '家電、AV、カメラ', yahooId: 23972 },
  { id: 'record', label: 'レコード', yahooId: 22308 }
];

export function getCategoryById(id: string): Category | undefined {
  return CATEGORIES.find(cat => cat.id === id);
}

export function getCategoryByYahooId(yahooId: number): Category | undefined {
  return CATEGORIES.find(cat => cat.yahooId === yahooId);
}

export function getCategoryLabel(id: string): string {
  return getCategoryById(id)?.label || '未設定';
}

export function getYahooId(id: string): number | undefined {
  return getCategoryById(id)?.yahooId;
}