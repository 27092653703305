import React, { useState, useRef, useEffect } from 'react';
import { motion, useMotionValue } from 'framer-motion';

interface ZoomableImageProps {
  src: string;
  alt?: string;
  onZoom?: (isZoomed: boolean) => void;
}

export function ZoomableImage({ src, alt = '', onZoom }: ZoomableImageProps) {
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    const newScale = Math.min(Math.max(1, scale + delta), 4);
    setScale(newScale);
    onZoom?.(newScale > 1);
    
    if (newScale === 1) {
      x.set(0);
      y.set(0);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (scale > 1) {
      setScale(1);
      x.set(0);
      y.set(0);
      onZoom?.(false);
    } else {
      setScale(2);
      onZoom?.(true);
    }
  };

  const handleDragStart = () => {
    if (scale > 1) {
      setIsDragging(true);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
      return () => container.removeEventListener('wheel', handleWheel);
    }
  }, [scale]);

  // Reset zoom and position when image changes
  useEffect(() => {
    setScale(1);
    x.set(0);
    y.set(0);
  }, [src]);

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full flex items-center justify-center overflow-hidden"
      style={{ cursor: scale > 1 ? 'grab' : 'zoom-in' }}
      onClick={e => e.stopPropagation()}
    >
      <motion.div
        style={{
          x,
          y,
          scale,
          cursor: isDragging ? 'grabbing' : scale > 1 ? 'grab' : 'zoom-in'
        }}
        drag={scale > 1}
        dragMomentum={false}
        dragElastic={0.1}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        whileDrag={{ cursor: 'grabbing' }}
      >
        <img
          ref={imageRef}
          src={src}
          alt={alt}
          onClick={handleClick}
          className="max-w-full max-h-[90vh] object-contain select-none"
          draggable={false}
        />
      </motion.div>
    </div>
  );
}