import React, { useState } from 'react';
import { X, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface TitleGenerationProgressProps {
  currentIndex: number;
  totalCount: number;
  onCancel: () => void;
}

export function TitleGenerationProgress({ 
  currentIndex, 
  totalCount,
  onCancel 
}: TitleGenerationProgressProps) {
  const [isCanceling, setIsCanceling] = useState(false);
  const progress = ((currentIndex + 1) / totalCount) * 100;

  const handleCancel = async () => {
    setIsCanceling(true);
    await new Promise(resolve => setTimeout(resolve, 300));
    onCancel();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-gray-900">タイトルを生成中</h3>
          <motion.button
            onClick={handleCancel}
            disabled={isCanceling}
            whileTap={{ scale: 0.95 }}
            className="relative w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <AnimatePresence mode="wait">
              {isCanceling ? (
                <motion.div
                  key="canceling"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-full"
                >
                  <span className="text-xs font-medium text-gray-600">
                    キャンセル中...
                  </span>
                </motion.div>
              ) : (
                <motion.div
                  key="icon"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <X className="w-5 h-5" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.button>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-3">
            <Loader2 className="w-5 h-5 text-blue-600 animate-spin" />
            <span className="text-sm text-gray-600">
              商品 {currentIndex + 1}/{totalCount} を処理中...
            </span>
          </div>

          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block text-blue-600">
                  {Math.round(progress)}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-100">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.3 }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600"
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end">
          <motion.button
            onClick={handleCancel}
            disabled={isCanceling}
            whileTap={{ scale: 0.95 }}
            className="relative px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px] h-10 overflow-hidden"
          >
            <AnimatePresence mode="wait">
              {isCanceling ? (
                <motion.div
                  key="canceling"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="absolute inset-0 flex items-center justify-center bg-gray-200"
                >
                  <span className="font-medium text-gray-700">キャンセル中...</span>
                </motion.div>
              ) : (
                <motion.span
                  key="cancel"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="absolute inset-0 flex items-center justify-center"
                >
                  キャンセル
                </motion.span>
              )}
            </AnimatePresence>
          </motion.button>
        </div>
      </div>
    </div>
  );
}