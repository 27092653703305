import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProductList } from './components/ProductList';
import { FolderList } from './components/folders/FolderList';
import { Header } from './components/Header';
import { DownloadActions } from './components/downloads/DownloadActions';
import { useProductStore } from './stores/productStore';
import { useFolderStore } from './stores/folderStore';
import type { BulkUpdates } from './types/bulk';

export function App() {
  const {
    products,
    updateProduct,
    deleteProduct,
    updateMultipleProducts
  } = useProductStore();

  const {
    currentFolderId
  } = useFolderStore();

  const filteredProducts = products.filter(product => {
    if (currentFolderId === null) {
      return true;
    }
    return product.folderId === currentFolderId;
  });

  const handleBulkUpdate = (updates: BulkUpdates, selectedIds: number[]) => {
    updateMultipleProducts(updates, selectedIds);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="min-h-screen bg-gray-50">
              <Header />
              <div className="py-4 sm:py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <DownloadActions />

                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                    <div className="lg:col-span-1">
                      <FolderList />
                    </div>
                    <div className="lg:col-span-3">
                      <ProductList
                        products={filteredProducts}
                        onUpdate={updateProduct}
                        onDelete={deleteProduct}
                        onBulkUpdate={handleBulkUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}