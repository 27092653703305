import { processImageBatch } from './imageProcessing';
import type { ProcessingProgress } from './imageProcessing';

export interface ImageGroup {
  prefix: string;
  files: File[];
  originals: string[];
  thumbnails: string[];
}

export async function processImageGroups(
  files: FileList,
  onProgress?: (progress: ProcessingProgress) => void
): Promise<ImageGroup[]> {
  const sortedFiles = Array.from(files)
    .filter(file => file.type.startsWith('image/'))
    .sort((a, b) => a.name.localeCompare(b.name));

  const processedImages = await processImageBatch(sortedFiles, onProgress);
  
  return [{
    prefix: 'group',
    files: sortedFiles,
    originals: processedImages.map(img => img.original),
    thumbnails: processedImages.map(img => img.thumbnail)
  }];
}

export function getInitialGroupSizes(totalImages: number, defaultSize: number): { [key: string]: number } {
  const sizes: { [key: string]: number } = {};
  let remainingImages = totalImages;
  let currentIndex = 0;

  while (remainingImages > 0) {
    const size = Math.min(10, Math.min(defaultSize, remainingImages));
    sizes[currentIndex.toString()] = size;
    remainingImages -= size;
    currentIndex++;
  }

  return sizes;
}

export function createImageGroups(
  files: File[],
  originals: string[],
  thumbnails: string[],
  groupSizes: { [key: string]: number }
): ImageGroup[] {
  const groups: ImageGroup[] = [];
  let currentIndex = 0;

  const sortedEntries = Object.entries(groupSizes)
    .sort(([a], [b]) => Number(a) - Number(b));

  for (const [_, size] of sortedEntries) {
    if (currentIndex < files.length) {
      const groupSize = Math.min(10, size);
      const groupFiles = files.slice(currentIndex, currentIndex + groupSize);
      const groupOriginals = originals.slice(currentIndex, currentIndex + groupSize);
      const groupThumbnails = thumbnails.slice(currentIndex, currentIndex + groupSize);

      if (groupFiles.length > 0) {
        groups.push({
          prefix: `group${groups.length}`,
          files: groupFiles,
          originals: groupOriginals,
          thumbnails: groupThumbnails
        });
      }

      currentIndex += groupSize;
    }
  }

  return groups;
}

export function updateGroupSize(
  allFiles: File[],
  allOriginals: string[],
  allThumbnails: string[],
  groupSizes: { [key: string]: number },
  groupIndex: number,
  newSize: number
): { updatedSizes: { [key: string]: number }; updatedGroups: ImageGroup[] } {
  const validatedSize = Math.max(1, Math.min(10, Math.min(newSize, allFiles.length)));
  const updatedSizes = { ...groupSizes };
  updatedSizes[groupIndex] = validatedSize;

  const updatedGroups = createImageGroups(
    allFiles,
    allOriginals,
    allThumbnails,
    updatedSizes
  );

  return {
    updatedSizes,
    updatedGroups
  };
}

export function reorderImages(group: ImageGroup, fromIndex: number, toIndex: number): ImageGroup {
  const moveItem = <T>(arr: T[], from: number, to: number): T[] => {
    const newArr = [...arr];
    const [item] = newArr.splice(from, 1);
    newArr.splice(to, 0, item);
    return newArr;
  };

  return {
    ...group,
    files: moveItem(group.files, fromIndex, toIndex),
    originals: moveItem(group.originals, fromIndex, toIndex),
    thumbnails: moveItem(group.thumbnails, fromIndex, toIndex)
  };
}

export async function processGroupFiles(files: File[]): Promise<{ originals: string[]; thumbnails: string[] }> {
  const processedImages = await processImageBatch(files);
  return {
    originals: processedImages.map(img => img.original),
    thumbnails: processedImages.map(img => img.thumbnail)
  };
}