import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface GroupSizeSelectorProps {
  onSelect: (size: number) => void;
  onClose: () => void;
  defaultSize?: number;
  totalImages: number;
}

export function GroupSizeSelector({ 
  onSelect, 
  onClose, 
  defaultSize = 4,
  totalImages
}: GroupSizeSelectorProps) {
  const sizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const numGroups = (size: number) => Math.ceil(totalImages / size);
  const [isCanceling, setIsCanceling] = useState(false);

  const handleClose = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCanceling(true);
    await new Promise(resolve => setTimeout(resolve, 300));
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-medium text-gray-900">商品あたりの写真枚数</h2>
          <motion.button
            onClick={handleClose}
            disabled={isCanceling}
            whileTap={{ scale: 0.95 }}
            className={`
              relative p-2 text-gray-400 rounded-full 
              hover:text-gray-600 hover:bg-gray-100 
              disabled:cursor-not-allowed disabled:opacity-75
              overflow-hidden
            `}
          >
            <X className="w-5 h-5" />
            <AnimatePresence>
              {isCanceling && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute inset-0 flex items-center justify-center bg-gray-100"
                >
                  <span className="text-xs font-medium text-gray-600">
                    キャンセル中...
                  </span>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.button>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-5 gap-4">
            {sizes.map(size => (
              <motion.button
                key={size}
                onClick={() => onSelect(size)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`aspect-square flex flex-col items-center justify-center rounded-lg border-2 transition-colors ${
                  size === defaultSize
                    ? 'border-blue-500 bg-blue-50 text-blue-600'
                    : 'border-gray-200 hover:border-blue-500 hover:bg-blue-50'
                }`}
              >
                <span className="text-lg font-medium">{size}</span>
                <span className="text-xs text-gray-500 mt-1">
                  {numGroups(size)}商品
                </span>
              </motion.button>
            ))}
          </div>
          <p className="mt-4 text-sm text-gray-600">
            1グループあたりの写真枚数を選択してください。
            この設定はデフォルト値として使用され、後から各グループごとに変更できます。
          </p>
          <p className="mt-2 text-sm text-gray-500">
            全{totalImages}枚の写真を{numGroups(defaultSize)}個の商品に分割します。
          </p>
        </div>
      </div>
    </div>
  );
}