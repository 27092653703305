import React from 'react';
import { Gavel, Tag } from 'lucide-react';

interface ListingTypeToggleProps {
  value: 'auction' | 'fixed';
  onChange: (value: 'auction' | 'fixed') => void;
}

export const ListingTypeToggle: React.FC<ListingTypeToggleProps> = ({ value, onChange }) => {
  return (
    <div className="flex gap-2">
      <button
        onClick={() => onChange('auction')}
        className={`flex-1 flex items-center justify-center gap-2 py-2 px-4 rounded-md transition-colors duration-200 ${
          value === 'auction'
            ? 'bg-blue-600 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        }`}
      >
        <Gavel className="w-4 h-4" />
        <span>オークション</span>
      </button>
      <button
        onClick={() => onChange('fixed')}
        className={`flex-1 flex items-center justify-center gap-2 py-2 px-4 rounded-md transition-colors duration-200 ${
          value === 'fixed'
            ? 'bg-blue-600 text-white'
            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
        }`}
      >
        <Tag className="w-4 h-4" />
        <span>定額</span>
      </button>
    </div>
  );
};