import { openDB } from 'idb';
import { DB_NAME, DB_VERSION, STORES, INDEXES } from './config';

export async function initDB() {
  try {
    const db = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        // Products store
        if (!db.objectStoreNames.contains(STORES.PRODUCTS)) {
          const productStore = db.createObjectStore(STORES.PRODUCTS, {
            keyPath: 'id',
            autoIncrement: true
          });
          productStore.createIndex(INDEXES.USER_SERIAL_NUMBER, ['userId', 'serialNumber'], { unique: true });
          productStore.createIndex(INDEXES.FOLDER_ID, 'folderId');
          productStore.createIndex(INDEXES.USER_ID, 'userId');
          productStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }

        // Images store
        if (!db.objectStoreNames.contains(STORES.IMAGES)) {
          const imageStore = db.createObjectStore(STORES.IMAGES, {
            keyPath: 'id',
            autoIncrement: true
          });
          imageStore.createIndex(INDEXES.PRODUCT_ID, 'productId');
          imageStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }

        // Folders store
        if (!db.objectStoreNames.contains(STORES.FOLDERS)) {
          const folderStore = db.createObjectStore(STORES.FOLDERS, {
            keyPath: 'id',
            autoIncrement: true
          });
          folderStore.createIndex(INDEXES.USER_ID, 'userId');
          folderStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }

        // Users store
        if (!db.objectStoreNames.contains(STORES.USERS)) {
          const userStore = db.createObjectStore(STORES.USERS, {
            keyPath: 'email'
          });
          userStore.createIndex(INDEXES.EMAIL, 'email', { unique: true });
          userStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }

        // Templates store
        if (!db.objectStoreNames.contains(STORES.TEMPLATES)) {
          const templateStore = db.createObjectStore(STORES.TEMPLATES, {
            keyPath: 'id'
          });
          templateStore.createIndex(INDEXES.USER_ID, 'userId');
          templateStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }

        // Handle version upgrades
        if (oldVersion < 12) {
          // If templates store exists, delete and recreate it
          if (db.objectStoreNames.contains(STORES.TEMPLATES)) {
            db.deleteObjectStore(STORES.TEMPLATES);
          }
          const templateStore = db.createObjectStore(STORES.TEMPLATES, {
            keyPath: 'id'
          });
          templateStore.createIndex(INDEXES.USER_ID, 'userId');
          templateStore.createIndex(INDEXES.CREATED_AT, 'createdAt');
        }
      }
    });

    return db;
  } catch (error) {
    console.error('Failed to initialize database:', error);
    throw new Error('データベースの初期化に失敗しました');
  }
}

export * from './config';
export * from './operations';
export * from './schema';