import React, { useRef, useState, useEffect } from 'react';
import { Camera, X, SunMedium, RotateCcw } from 'lucide-react';
import { processImageFiles } from '../../utils/imageUtils';

interface CameraCaptureProps {
  onImagesCapture: (images: string[]) => void;
  currentImages: string[];
}

export function CameraCapture({ onImagesCapture, currentImages }: CameraCaptureProps) {
  const [showCamera, setShowCamera] = useState(false);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isInitializing, setIsInitializing] = useState(false);
  const [brightness, setBrightness] = useState(1.2);
  const [facingMode, setFacingMode] = useState<'environment' | 'user'>('environment');
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const initializeCamera = async (facing: 'environment' | 'user') => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('お使いのブラウザはカメラをサポートしていません');
      }

      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }

      // First, try to get the list of available cameras
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      // Default constraints
      let constraints: MediaStreamConstraints = {
        video: {
          facingMode: facing,
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        },
        audio: false
      };

      // For iOS Safari and other mobile browsers
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      if (videoDevices.length > 0) {
        if (isIOS) {
          // On iOS, try to select the appropriate camera based on facing mode
          const device = facing === 'environment' 
            ? videoDevices.find(d => !d.label.toLowerCase().includes('front'))
            : videoDevices.find(d => d.label.toLowerCase().includes('front'));
          
          if (device) {
            constraints.video = {
              deviceId: { exact: device.deviceId },
              width: { ideal: 1920 },
              height: { ideal: 1080 }
            };
          }
        } else if (isMobile) {
          // For other mobile devices, use simpler constraints
          constraints.video = {
            facingMode: facing,
            width: { ideal: 1280 },
            height: { ideal: 720 }
          };
        }
      }

      // Try to get the stream with the current constraints
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
          
          // Wait for the video to be ready
          await new Promise<void>((resolve, reject) => {
            if (videoRef.current) {
              videoRef.current.onloadedmetadata = () => {
                if (videoRef.current) {
                  videoRef.current.play()
                    .then(() => resolve())
                    .catch(reject);
                }
              };
              videoRef.current.onerror = () => reject(new Error('ビデオの読み込みに失敗しました'));
            }
          });
        }
        return true;
      } catch (err) {
        console.warn('First attempt failed, trying fallback:', err);
        
        // Fallback: try without exact constraints
        constraints.video = {
          facingMode: facing,
          width: { ideal: 1280 },
          height: { ideal: 720 }
        };
        
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
          
          await new Promise<void>((resolve, reject) => {
            if (videoRef.current) {
              videoRef.current.onloadedmetadata = () => {
                if (videoRef.current) {
                  videoRef.current.play()
                    .then(() => resolve())
                    .catch(reject);
                }
              };
              videoRef.current.onerror = () => reject(new Error('ビデオの読み込みに失敗しました'));
            }
          });
        }
        return true;
      }
    } catch (err) {
      console.error('Camera initialization error:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('カメラの初期化に失敗しました');
      }
      return false;
    }
  };

  const startCamera = async () => {
    setIsInitializing(true);
    setError(null);
    
    try {
      // Check camera permission
      const permissionResult = await navigator.permissions.query({ name: 'camera' as PermissionName });
      
      if (permissionResult.state === 'denied') {
        throw new Error('カメラへのアクセスが拒否されています。ブラウザの設定で許可してください。');
      }

      // Try to initialize with the rear camera first
      let success = await initializeCamera('environment');
      
      // If rear camera fails, try the front camera
      if (!success) {
        setFacingMode('user');
        success = await initializeCamera('user');
      }

      if (success) {
        setShowCamera(true);
      } else {
        throw new Error('カメラを起動できませんでした');
      }
    } catch (err) {
      console.error('Camera error:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('カメラの起動に失敗しました');
      }
    } finally {
      setIsInitializing(false);
    }
  };

  const switchCamera = async () => {
    const newFacingMode = facingMode === 'environment' ? 'user' : 'environment';
    setIsInitializing(true);
    
    try {
      const success = await initializeCamera(newFacingMode);
      if (success) {
        setFacingMode(newFacingMode);
      }
    } finally {
      setIsInitializing(false);
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setShowCamera(false);
    setError(null);
  };

  const adjustBrightness = () => {
    setBrightness(prev => prev >= 2 ? 1 : prev + 0.2);
  };

  const captureImage = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Apply brightness and contrast adjustments
    ctx.filter = `brightness(${brightness * 100}%) contrast(110%)`;
    
    // Handle mirroring for front camera
    if (facingMode === 'user') {
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
    }
    
    ctx.drawImage(videoRef.current, 0, 0);
    ctx.filter = 'none';
    
    // Convert to blob and process
    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const file = new File([blob], `capture-${Date.now()}.jpg`, { type: 'image/jpeg' });
      const fileList = new DataTransfer();
      fileList.items.add(file);
      
      const newImages = await processImageFiles(fileList.files, currentImages);
      setCapturedImages(prev => [...prev, newImages[newImages.length - 1]]);
    }, 'image/jpeg', 0.95);
  };

  const handleSave = () => {
    onImagesCapture(capturedImages);
    setCapturedImages([]);
    stopCamera();
  };

  const handleCancel = () => {
    setCapturedImages([]);
    stopCamera();
  };

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  if (!showCamera) {
    return (
      <button
        onClick={startCamera}
        disabled={isInitializing}
        className={`flex items-center gap-2 px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
          isInitializing 
            ? 'bg-gray-400 cursor-wait' 
            : 'bg-blue-600 hover:bg-blue-700'
        }`}
      >
        <Camera className="w-5 h-5" />
        <span>{isInitializing ? 'カメラ起動中...' : 'カメラで撮影'}</span>
      </button>
    );
  }

  return (
    <div className="fixed inset-0 z-50 bg-black">
      <div className="relative h-full flex flex-col">
        {error ? (
          <div className="flex-1 flex items-center justify-center p-4">
            <div className="text-white text-center">
              <p className="mb-4">{error}</p>
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-white text-black rounded-md"
              >
                閉じる
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex-1 relative">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className="absolute inset-0 w-full h-full object-cover"
                style={{
                  filter: `brightness(${brightness}) contrast(1.1)`,
                  transform: facingMode === 'user' ? 'scaleX(-1)' : 'none'
                }}
              />
              
              {capturedImages.length > 0 && (
                <div className="absolute bottom-4 left-4 right-4 bg-black bg-opacity-50 p-2 rounded-lg">
                  <div className="flex gap-2 overflow-x-auto pb-2">
                    {capturedImages.map((image, index) => (
                      <div key={index} className="flex-shrink-0 w-16 h-16 relative">
                        <img
                          src={image}
                          alt={`撮影画像 ${index + 1}`}
                          className="w-full h-full object-cover rounded"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="absolute top-4 right-4 flex gap-2">
              <button
                onClick={switchCamera}
                className="p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
                title="カメラを切り替え"
              >
                <RotateCcw className="w-6 h-6" />
              </button>
              <button
                onClick={adjustBrightness}
                className="p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
                title="明るさを調整"
              >
                <SunMedium className="w-6 h-6" />
              </button>
              <button
                onClick={handleCancel}
                className="p-2 bg-red-600 text-white rounded-full hover:bg-red-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <div className="p-4 bg-black">
              <div className="flex justify-between items-center">
                <button
                  onClick={captureImage}
                  disabled={isInitializing}
                  className="w-16 h-16 bg-white rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <div className="w-14 h-14 border-4 border-blue-600 rounded-full" />
                </button>
                
                <button
                  onClick={handleSave}
                  disabled={capturedImages.length === 0}
                  className={`px-6 py-2 rounded-md text-white font-medium ${
                    capturedImages.length > 0
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-gray-600 cursor-not-allowed'
                  }`}
                >
                  保存 ({capturedImages.length}枚)
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}