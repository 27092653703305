import React from 'react';
import { Minus, Plus } from 'lucide-react';

interface GroupSizeAdjusterProps {
  currentSize: number;
  onChange: (size: number) => void;
  maxSize: number;
  totalImages: number;
}

export function GroupSizeAdjuster({
  currentSize,
  onChange,
  maxSize,
  totalImages
}: GroupSizeAdjusterProps) {
  const handleDecrease = () => {
    if (currentSize > 1) {
      onChange(currentSize - 1);
    }
  };

  const handleIncrease = () => {
    // Enforce maximum of 10 images per group
    if (currentSize < Math.min(10, totalImages)) {
      onChange(currentSize + 1);
    }
  };

  return (
    <div className="flex items-center gap-2 bg-white rounded-lg p-2 shadow-sm">
      <button
        onClick={handleDecrease}
        disabled={currentSize <= 1}
        className="p-1.5 rounded-md text-gray-600 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        title="写真を1枚減らす"
      >
        <Minus className="w-4 h-4" />
      </button>
      <span className="text-sm font-medium min-w-[3ch] text-center">
        {currentSize}枚
      </span>
      <button
        onClick={handleIncrease}
        disabled={currentSize >= Math.min(10, totalImages)}
        className="p-1.5 rounded-md text-gray-600 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        title="写真を1枚増やす"
      >
        <Plus className="w-4 h-4" />
      </button>
    </div>
  );
}