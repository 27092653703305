export const OPENAI_CONFIG = {
  model: "gpt-4o",
  maxTokens: 300,
  temperature: 0.7,
  topP: 0.9,
  frequencyPenalty: 0.2,
  presencePenalty: 0.2,
  maxImages: 3,
  maxRetries: 3,
  retryDelay: 1000,
  timeout: 30000
};

export const PROMPT_TEMPLATES = {
  titleGeneration: `画像を解析してフリマサイトで販売する商品のタイトルを作成してください。
タイトルは最も可能性高い情報を参考に[残りの文字数]以内で、なるべく長いタイトルを付けて下さい。
商品カテゴリは文頭に、出力は商品名のみ、コンディションは含まない、受信専用`
};