import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { GroupSettings } from './GroupSettings';

interface GroupSettingsCollapsibleProps {
  groupIndex: number;
  category: string;
  startPrice: number;
  condition: string;
  shippingMethods?: string[];
  customShippingMethods?: any[];
  onChange: (groupIndex: number, updates: { 
    category?: string; 
    startPrice?: number; 
    condition?: string;
    shippingMethods?: string[];
    customShippingMethods?: any[];
  }) => void;
}

export function GroupSettingsCollapsible({
  groupIndex,
  category,
  startPrice,
  condition,
  shippingMethods,
  customShippingMethods,
  onChange
}: GroupSettingsCollapsibleProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-4 border rounded-lg overflow-hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-3 bg-gray-50 flex items-center justify-between hover:bg-gray-100 transition-colors"
      >
        <span className="font-medium text-gray-700">商品設定</span>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-gray-400" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-400" />
        )}
      </button>
      {isOpen && (
        <div className="p-4 bg-white">
          <GroupSettings
            groupIndex={groupIndex}
            category={category}
            startPrice={startPrice}
            condition={condition}
            shippingMethods={shippingMethods}
            customShippingMethods={customShippingMethods}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}