import React from 'react';
import { LogOut, Settings, User, HelpCircle } from 'lucide-react';

interface UserDropdownProps {
  onLogout: () => void;
}

export function UserDropdown({ onLogout }: UserDropdownProps) {
  return (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50">
      <div className="px-4 py-2 border-b border-gray-100">
        <p className="text-sm font-medium text-gray-900">アカウント</p>
      </div>

      <div className="py-1">
        <button
          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
          onClick={() => {/* TODO: Implement profile */}}
        >
          <User className="w-4 h-4" />
          <span>プロフィール</span>
        </button>
        
        <button
          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
          onClick={() => {/* TODO: Implement settings */}}
        >
          <Settings className="w-4 h-4" />
          <span>設定</span>
        </button>

        <button
          className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
          onClick={() => {/* TODO: Implement help */}}
        >
          <HelpCircle className="w-4 h-4" />
          <span>ヘルプ</span>
        </button>
      </div>

      <div className="border-t border-gray-100">
        <button
          onClick={onLogout}
          className="w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
        >
          <LogOut className="w-4 h-4" />
          <span>ログアウト</span>
        </button>
      </div>
    </div>
  );
}