import React from 'react';

interface AuctionOptionsProps {
  ratingRestriction: boolean;
  badRatingRestriction: boolean;
  bidderAuthentication: boolean;
  returnsAccepted: boolean;
  autoExtensionByBid: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showAutoExtension?: boolean;
}

export const AuctionOptions: React.FC<AuctionOptionsProps> = ({
  ratingRestriction,
  badRatingRestriction,
  bidderAuthentication,
  returnsAccepted,
  autoExtensionByBid,
  onChange,
  showAutoExtension = true
}) => {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">オプション設定</h4>
      <div className="space-y-3">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="ratingRestriction"
            checked={ratingRestriction}
            onChange={onChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">総合評価で入札を制限する</span>
        </label>
        
        <label className="flex items-center">
          <input
            type="checkbox"
            name="badRatingRestriction"
            checked={badRatingRestriction}
            onChange={onChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">悪い評価の割合で入札を制限する</span>
        </label>
        
        <label className="flex items-center">
          <input
            type="checkbox"
            name="bidderAuthentication"
            checked={bidderAuthentication}
            onChange={onChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">入札者認証制限</span>
        </label>

        {showAutoExtension && (
          <label className="flex items-center">
            <input
              type="checkbox"
              name="autoExtensionByBid"
              checked={autoExtensionByBid}
              onChange={onChange}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">入札による自動延長</span>
          </label>
        )}
        
        <label className="flex items-center">
          <input
            type="checkbox"
            name="returnsAccepted"
            checked={returnsAccepted}
            onChange={onChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">返品を受け付ける</span>
        </label>
      </div>
    </div>
  );
};