const CONDITIONS: { [key: string]: string } = {
  'new': '未使用',
  'like-new': '未使用に近い',
  'good': '目立った傷や汚れなし',
  'fair': 'やや傷や汚れあり',
  'poor': '傷や汚れあり',
  'bad': '全体的に状態が悪い'
};

export function formatCondition(condition: string): string {
  return CONDITIONS[condition] || '';
}