import { STORES, INDEXES } from '../config';
import { executeTransaction } from './baseOperations';
import type { User } from '../../types/auth';

export async function createUser(user: User): Promise<void> {
  return executeTransaction(STORES.USERS, 'readwrite', async (tx) => {
    await tx.objectStore(STORES.USERS).add({
      ...user,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  });
}

export async function getUserByEmail(email: string): Promise<User | undefined> {
  return executeTransaction(STORES.USERS, 'readonly', async (tx) => {
    return tx.objectStore(STORES.USERS).get(email);
  });
}

export async function updateUser(email: string, updates: Partial<User>): Promise<void> {
  return executeTransaction(STORES.USERS, 'readwrite', async (tx) => {
    const store = tx.objectStore(STORES.USERS);
    const user = await store.get(email);
    
    if (user) {
      await store.put({
        ...user,
        ...updates,
        updatedAt: new Date().toISOString()
      });
    }
  });
}