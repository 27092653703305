import type { ShippingMethodOption } from '../../types/shipping';

export const PREDEFINED_SHIPPING_METHODS: ShippingMethodOption[] = [
  { value: 'clickpost', label: 'クリックポスト', hasRegionalPricing: false },
  { value: 'regular-mail', label: '定形郵便', hasRegionalPricing: false },
  { value: 'non-regular-mail', label: '定形外郵便', hasRegionalPricing: false },
  { value: 'letter-pack-plus', label: 'レターパックプラス', hasRegionalPricing: false },
  { value: 'letter-pack-light', label: 'レターパックライト', hasRegionalPricing: false },
  { value: 'yu-mail', label: 'ゆうメール', hasRegionalPricing: false },
  { value: 'yu-pack-flat', label: 'ゆうパックフラット', hasRegionalPricing: false },
  { value: 'yamato-transport', label: '宅急便（ヤマト運輸）', hasRegionalPricing: true },
  { value: 'sagawa-mail', label: '飛脚メール便（佐川急便）', hasRegionalPricing: false },
  { value: 'sagawa-express', label: '飛脚宅急便（佐川急便）', hasRegionalPricing: true },
  { value: 'seino-transport', label: 'カンガルー便（西濃運輸）', hasRegionalPricing: true },
  { value: 'other', label: 'その他', hasRegionalPricing: true }
];