import React from 'react';
import { CATEGORIES } from '../../utils/categories';

interface CategorySelectorProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function CategorySelector({ value, onChange }: CategorySelectorProps) {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">カテゴリ</h4>
      <select
        name="category"
        value={value}
        onChange={onChange}
        className="w-full rounded-md border-gray-300 bg-gray-50 text-sm"
      >
        <option value="">選択してください</option>
        {CATEGORIES.map(category => (
          <option key={category.id} value={category.id}>
            {category.label}
          </option>
        ))}
      </select>
    </div>
  );
}