import { STORES, INDEXES } from '../config';
import { executeTransaction } from './baseOperations';
import type { Product } from '../../types/product';

export async function createProduct(product: Omit<Product, 'id'>, userId: string): Promise<number> {
  return executeTransaction(STORES.PRODUCTS, 'readwrite', async (tx) => {
    const store = tx.objectStore(STORES.PRODUCTS);
    
    // Get max serial number
    const products = await store.index(INDEXES.USER_ID).getAll(userId);
    const maxSerial = Math.max(0, ...products.map(p => p.serialNumber));
    
    const newProduct = {
      ...product,
      userId,
      serialNumber: maxSerial + 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    return await store.add(newProduct);
  });
}

export async function updateProductById(id: number, product: Product): Promise<void> {
  return executeTransaction(STORES.PRODUCTS, 'readwrite', async (tx) => {
    const store = tx.objectStore(STORES.PRODUCTS);
    
    const existingProduct = await store.get(id);
    if (!existingProduct) {
      throw new Error('Product not found');
    }

    await store.put({
      ...product,
      id,
      userId: existingProduct.userId,
      serialNumber: existingProduct.serialNumber,
      createdAt: existingProduct.createdAt,
      updatedAt: new Date().toISOString()
    });
  });
}

export async function getProductsByUser(userId: string): Promise<Product[]> {
  return executeTransaction(STORES.PRODUCTS, 'readonly', async (tx) => {
    const products = await tx.objectStore(STORES.PRODUCTS)
      .index(INDEXES.USER_ID)
      .getAll(userId);
    
    return products.sort((a, b) => b.serialNumber - a.serialNumber);
  });
}

export async function deleteProductById(id: number): Promise<void> {
  return executeTransaction([STORES.PRODUCTS, STORES.IMAGES], 'readwrite', async (tx) => {
    // Delete images first
    const imageStore = tx.objectStore(STORES.IMAGES);
    const imageKeys = await imageStore.index(INDEXES.PRODUCT_ID).getAllKeys(id);
    await Promise.all(imageKeys.map(key => imageStore.delete(key)));

    // Then delete the product
    await tx.objectStore(STORES.PRODUCTS).delete(id);
  });
}

export async function getMaxSerialNumberByUser(userId: string): Promise<number> {
  return executeTransaction(STORES.PRODUCTS, 'readonly', async (tx) => {
    const products = await tx.objectStore(STORES.PRODUCTS)
      .index(INDEXES.USER_ID)
      .getAll(userId);
    
    return Math.max(0, ...products.map(p => p.serialNumber));
  });
}