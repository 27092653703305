import { Product } from '../../types';
import { CSVOptions } from '../../types/csv';
import { generateCSV } from './generator';

export { generateCSV } from './generator';

export function downloadCSV(products: Product[], options: CSVOptions = {}): void {
  const csv = generateCSV(products, options);
  const timestamp = new Date().toISOString().split('T')[0];
  const filename = `yahoo_auction_${timestamp}.csv`;
  
  // Add BOM for UTF-8
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const csvContent = new TextEncoder().encode(csv);
  const blob = new Blob([bom, csvContent], { 
    type: 'text/csv;charset=utf-8' 
  });
  
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}