export const VALID_PACKAGE_SIZES = [
  '～60cm',
  '～80cm',
  '～100cm',
  '～120cm',
  '～140cm',
  '～160cm',
  '～180cm',
  '～200cm'
] as const;

export const VALID_PACKAGE_WEIGHTS = [
  '～2kg',
  '～5kg',
  '～10kg',
  '～15kg',
  '～20kg',
  '～25kg',
  '～30kg'
] as const;

export type ValidPackageSize = typeof VALID_PACKAGE_SIZES[number];
export type ValidPackageWeight = typeof VALID_PACKAGE_WEIGHTS[number];

export function formatShippingTime(time: string): string {
  const timeMap: { [key: string]: string } = {
    '1-2': '1日～2日',
    '2-3': '2日～3日',
    '3-7': '3日～7日'
  };
  return timeMap[time] || '';
}