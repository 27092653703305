import React from 'react';

interface ShippingOptionsProps {
  shippingLater: boolean;
  shippingCOD: boolean;
  shippingResponsibility: string;
  onChange: (name: string, checked: boolean) => void;
  onShippingResponsibilityChange: (value: string) => void;
}

export const ShippingOptions: React.FC<ShippingOptionsProps> = ({
  shippingLater = false,
  shippingCOD = false,
  shippingResponsibility = '',
  onChange,
  onShippingResponsibilityChange,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    
    // If checking a box, uncheck the other option
    if (checked) {
      if (name === 'shippingLater') {
        onChange('shippingCOD', false);
      } else if (name === 'shippingCOD') {
        onChange('shippingLater', false);
      }
    }
    
    onChange(name, checked);
  };

  // When responsibility changes to seller, uncheck both options
  const handleResponsibilityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'seller') {
      onChange('shippingLater', false);
      onChange('shippingCOD', false);
    }
    onShippingResponsibilityChange(value);
  };

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">送料負担</label>
        <select
          value={shippingResponsibility}
          onChange={handleResponsibilityChange}
          className="w-full rounded-md border-gray-300 bg-gray-50"
        >
          <option value="">選択してください</option>
          <option value="buyer">落札者</option>
          <option value="seller">出品者</option>
        </select>
      </div>

      {shippingResponsibility === 'buyer' && (
        <div className="space-y-3">
          <label className="relative flex items-center cursor-pointer select-none">
            <input
              type="checkbox"
              name="shippingLater"
              checked={shippingLater}
              onChange={handleCheckboxChange}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">落札された後に送料を連絡する</span>
          </label>
          <label className="relative flex items-center cursor-pointer select-none">
            <input
              type="checkbox"
              name="shippingCOD"
              checked={shippingCOD}
              onChange={handleCheckboxChange}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">着払いにする</span>
          </label>
        </div>
      )}
    </div>
  );
};