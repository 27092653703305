import React from 'react';
import { Hash } from 'lucide-react';
import type { Product } from '../types';
import { getItemNumberDisplay } from '../utils/titleProcessor';

interface ItemNumberPreviewProps {
  product: Product;
  className?: string;
}

export function ItemNumberPreview({ product, className = '' }: ItemNumberPreviewProps) {
  const itemNumbers = getItemNumberDisplay(product);
  
  if (itemNumbers.length === 0) return null;

  return (
    <div className={`flex flex-wrap gap-1.5 ${className}`}>
      {itemNumbers.map((number, index) => (
        <div 
          key={index} 
          className={`inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs ${
            index === 0 
              ? 'bg-blue-50 text-blue-600 border border-blue-200'
              : 'bg-green-50 text-green-600 border border-green-200'
          }`}
        >
          <Hash className={`w-3 h-3 ${index === 0 ? 'text-blue-600' : 'text-green-600'}`} />
          <span>{number}</span>
          <span className="text-gray-400 text-[10px]">
            {index === 0 
              ? product.itemNumberPrefix 
                ? '(文頭)' 
                : product.itemNumberSuffix 
                  ? '(文尾)' 
                  : ''
              : product.memoPrefix 
                ? '(文頭)' 
                : product.memoSuffix 
                  ? '(文尾)' 
                  : ''
            }
          </span>
        </div>
      ))}
    </div>
  );
}