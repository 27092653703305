import React from 'react';
import { ListingTypeToggle } from './ListingTypeToggle';
import { ItemNumberInput } from './ItemNumberInput';
import { ItemNumberPreview } from './ItemNumberPreview';
import type { Product } from '../types';

interface ListingInfoProps {
  product: Product;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onListingTypeChange: (type: 'auction' | 'fixed') => void;
}

export function ListingInfo({ product, onChange, onListingTypeChange }: ListingInfoProps) {
  const handleItemNumberChange = (value: string, field: string) => {
    onChange({
      target: { name: field, value }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div className="space-y-6">
      <ListingTypeToggle
        value={product.listingType}
        onChange={onListingTypeChange}
      />

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {product.listingType === 'auction' ? '開始価格' : '販売価格'}
          </label>
          <input
            type="number"
            name="startPrice"
            value={product.startPrice || 0}
            onChange={onChange}
            min="0"
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
          />
        </div>
        {product.listingType === 'auction' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">即決価格</label>
            <input
              type="number"
              name="buyItNowPrice"
              value={product.buyItNowPrice === undefined ? '' : product.buyItNowPrice}
              onChange={onChange}
              min="0"
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-4">
          <ItemNumberInput
            value={product.itemNumber}
            onChange={(value) => handleItemNumberChange(value, 'itemNumber')}
            label="管理番号1"
          />
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="itemNumberPrefix"
                checked={product.itemNumberPrefix}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">文頭</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="itemNumberSuffix"
                checked={product.itemNumberSuffix}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">文尾</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="itemNumberSerial"
                checked={product.itemNumberSerial}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">連番</span>
            </label>
          </div>
        </div>

        <div className="space-y-4">
          <ItemNumberInput
            value={product.itemNumber2}
            onChange={(value) => handleItemNumberChange(value, 'itemNumber2')}
            label="管理番号2"
          />
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="memoPrefix"
                checked={product.memoPrefix}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">文頭</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="memoSuffix"
                checked={product.memoSuffix}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">文尾</span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="memoSerial"
                checked={product.memoSerial}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span className="ml-2 text-sm text-gray-600">連番</span>
            </label>
          </div>
        </div>
      </div>

      <ItemNumberPreview product={product} className="mt-4" />
    </div>
  );
}