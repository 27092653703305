import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';

interface ImageWithTooltipProps {
  src: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
  onDelete?: () => void;
  originalFilename?: string;
}

export function ImageWithTooltip({ 
  src, 
  alt = '', 
  className = '', 
  onClick,
  onDelete,
  originalFilename
}: ImageWithTooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div 
      className="relative group aspect-square"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <img
        src={src}
        alt={alt}
        className={`${className} cursor-pointer w-full h-full object-cover rounded-lg`}
        onClick={onClick}
      />
      {onDelete && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-600 z-10"
        >
          <Trash2 className="w-4 h-4" />
        </button>
      )}
      {showTooltip && originalFilename && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1.5 bg-gray-900 text-white text-xs rounded-md whitespace-nowrap z-50">
          {originalFilename}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900"></div>
        </div>
      )}
    </div>
  );
}