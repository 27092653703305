export interface ProcessingProgress {
  current: number;
  total: number;
  phase: 'reading' | 'processing' | 'complete';
}

export interface ProcessingOptions {
  maxWidth?: number;
  quality?: number;
  maxFileSize?: number;
}

export async function createThumbnail(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        // Calculate thumbnail dimensions
        const maxWidth = 300;
        const maxHeight = 300;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        // Create thumbnail
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        // Use better quality settings
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        
        // Draw and compress
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg', 0.85));
      };
      img.onerror = () => reject(new Error('Failed to load image'));
      img.src = reader.result as string;
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
}

export async function createImagePreview(file: File): Promise<{ original: string; thumbnail: string }> {
  if (file.size > 10 * 1024 * 1024) { // 10MB limit
    throw new Error('File size exceeds 10MB limit');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const thumbnail = await createThumbnail(file);
        resolve({
          original: reader.result as string,
          thumbnail
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
}

export async function processImageBatch(
  files: File[],
  onProgress?: (progress: ProcessingProgress) => void
): Promise<Array<{ original: string; thumbnail: string }>> {
  const validFiles = files.filter(file => file.type.startsWith('image/'));
  const sortedFiles = [...validFiles].sort((a, b) => a.name.localeCompare(b.name));
  
  const results: Array<{ original: string; thumbnail: string }> = [];
  let processed = 0;

  for (const file of sortedFiles) {
    try {
      if (onProgress) {
        onProgress({
          current: processed,
          total: sortedFiles.length,
          phase: 'processing'
        });
      }

      const result = await createImagePreview(file);
      results.push(result);
      processed++;

      if (onProgress) {
        onProgress({
          current: processed,
          total: sortedFiles.length,
          phase: processed === sortedFiles.length ? 'complete' : 'processing'
        });
      }
    } catch (error) {
      console.error(`Failed to process image: ${file.name}`, error);
    }
  }

  return results;
}