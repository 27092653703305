import React, { useState } from 'react';
import { ChevronDown, Package, Tag, Star, Truck, Trash2, Gavel, Folder, Hash, Upload, Wand2, StickyNote, Sparkles } from 'lucide-react';
import type { Product } from '../types';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { ImagePopup } from './folders/ImagePopup';
import { PriceDisplay } from './PriceDisplay';
import { EditableTitle } from './EditableTitle';
import { MemoInput } from './MemoInput';
import { DragDropImageUpload } from './DragDropImageUpload';
import { createDisplayTitle } from '../utils/memoUtils';
import { generateTitleFromImages } from '../utils/openai';
import { calculateCharacterCount } from '../utils/characterCount';
import { getItemNumberDisplay } from '../utils/titleProcessor';
import {
  getCategoryText,
  getConditionText,
  getShippingMethods
} from '../utils/productViewUtils';

interface ProductCompactViewProps {
  product: Product;
  onExpand: () => void;
  onDelete: () => void;
  isSelected?: boolean;
  onSelect?: (selected: boolean, event?: React.MouseEvent) => void;
  onUpdate?: (product: Product) => void;
}

export function ProductCompactView({
  product,
  onExpand,
  onDelete,
  isSelected = false,
  onSelect,
  onUpdate
}: ProductCompactViewProps) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isTitleEditing, setIsTitleEditing] = useState(false);

  const displayTitle = createDisplayTitle(product);
  const itemNumbers = getItemNumberDisplay(product);
  const characterCount = calculateCharacterCount(product);
  const shippingMethods = getShippingMethods(product);

  const getConditionStyle = (condition: string) => {
    switch (condition) {
      case 'new':
        return 'bg-blue-50 text-blue-600';
      case 'like-new':
      case 'good':
      case 'fair':
      case 'poor':
      case 'bad':
        return 'bg-green-50 text-green-600';
      default:
        return 'bg-gray-100 text-gray-600';
    }
  };

  const handleMemoChange = (value: string) => {
    if (onUpdate) {
      const updatedProduct = {
        ...product,
        memo: value
      };
      onUpdate(updatedProduct);
    }
  };

  const handleTitleChange = (value: string) => {
    if (onUpdate) {
      const updatedProduct = {
        ...product,
        title: value
      };
      onUpdate(updatedProduct);
    }
  };

  const handleGenerateTitle = async () => {
    if (!product.images.length || !onUpdate) return;

    setIsGenerating(true);
    try {
      const generatedTitle = await generateTitleFromImages(product.images);
      const updatedProduct = {
        ...product,
        title: generatedTitle
      };
      onUpdate(updatedProduct);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('タイトルの生成に失敗しました');
      }
      setTimeout(() => setError(null), 3000);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageClick = (index: number) => {
    setPreviewImageIndex(index);
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(!isSelected, e);
    }
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    if (!isTitleEditing) {
      onExpand();
    }
  };

  if (!onUpdate) {
    return null;
  }

  return (
    <DragDropImageUpload product={product} onUpdate={onUpdate}>
      <div 
        className="bg-white rounded-lg shadow-sm border border-gray-200 hover:border-gray-300 transition-colors cursor-pointer"
        onClick={handleContainerClick}
      >
        <div className="p-5">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex items-start gap-4 sm:w-48">
              {onSelect && (
                <div className="pt-0.5" onClick={handleCheckboxClick}>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => {}}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </div>
              )}

              {product.images.length > 0 ? (
                <div className="flex-1 sm:w-full">
                  <div className="aspect-square w-full">
                    <img
                      src={product.images[0]}
                      alt="商品画像"
                      className="w-full h-full object-cover rounded-md cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageClick(0);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-1 sm:w-full">
                  <div className="aspect-square w-full bg-gray-50 border-2 border-dashed border-gray-200 rounded-md flex flex-col items-center justify-center text-gray-400 hover:bg-gray-100 hover:border-gray-300 transition-colors">
                    <Upload className="w-8 h-8 mb-2" />
                    <span className="text-sm">写真を追加</span>
                    <span className="text-xs mt-1">ドラッグ＆ドロップ可能</span>
                  </div>
                </div>
              )}
            </div>

            <div className="flex-1 min-w-0 flex flex-col">
              <div>
                <div className="flex flex-wrap items-center gap-2 mb-4">
                  <span className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${getConditionStyle(product.condition)}`}>
                    {getConditionText(product.condition)}
                  </span>
                  <span className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
                    product.shippingResponsibility === 'seller'
                      ? 'bg-red-50 text-red-600'
                      : product.shippingCOD
                        ? 'bg-yellow-50 text-yellow-600'
                        : product.shippingLater
                          ? 'bg-purple-50 text-purple-600'
                          : 'bg-blue-50 text-blue-600'
                  }`}>
                    {product.shippingResponsibility === 'seller'
                      ? '送料込み'
                      : product.shippingCOD
                        ? '着払い'
                        : product.shippingLater
                          ? '落札後に送料を連絡'
                          : '落札者負担'}
                  </span>
                  <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-600">
                    {getCategoryText(product.category)}
                  </span>
                  {product.isFeatured && (
                    <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
                      <Star className="w-4 h-4" />
                      注目のオークション
                    </span>
                  )}
                  {product.recommendedCollection && (
                    <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-xs font-medium bg-amber-50 text-amber-600">
                      <Sparkles className="w-4 h-4" />
                      おすすめコレクション
                    </span>
                  )}
                </div>

                <EditableTitle
                  title={isTitleEditing ? product.title : displayTitle}
                  isGenerating={isGenerating}
                  product={product}
                  onTitleChange={handleTitleChange}
                  onEditStateChange={setIsTitleEditing}
                />

                <div className="mt-3 flex items-center justify-between">
                  <div className="flex-1">
                    <PriceDisplay
                      listingType={product.listingType}
                      startPrice={product.startPrice}
                      buyItNowPrice={product.buyItNowPrice || 0}
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    {!isTitleEditing && itemNumbers.length > 0 && (
                      <div className="flex gap-1.5">
                        {itemNumbers.map((number, index) => (
                          <span key={index} className={`inline-flex items-center gap-1 px-1.5 py-0.5 rounded text-xs ${
                            index === 0 
                              ? 'bg-blue-50 text-blue-600 border border-blue-200'
                              : 'bg-green-50 text-green-600 border border-green-200'
                          }`}>
                            <Hash className={`w-3 h-3 ${index === 0 ? 'text-blue-600' : 'text-green-600'}`} />
                            {number}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className="text-xs">
                      <span className={characterCount.isValid ? 'text-gray-500' : 'text-red-600 font-medium'}>
                        {characterCount.total}/65
                      </span>
                    </div>
                  </div>
                </div>

                {shippingMethods && (
                  <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                    <Package className="w-4 h-4 flex-shrink-0" />
                    <span className="truncate">{shippingMethods}</span>
                  </div>
                )}
              </div>

              <div className="mt-auto pt-4 border-t">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex-1">
                    <MemoInput
                      value={product.memo}
                      onChange={handleMemoChange}
                      onClick={(e) => e.stopPropagation()}
                      product={product}
                    />
                  </div>
                  
                  <div className="flex items-center gap-2">
                    {product.images.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGenerateTitle();
                        }}
                        disabled={isGenerating}
                        className="btn-ai-compact flex items-center justify-center gap-2 px-4"
                        title="AIでタイトルを生成"
                      >
                        <Wand2 className="w-4 h-4" />
                        <span>AIで生成</span>
                      </button>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteConfirmation(true);
                      }}
                      className="p-1 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          productTitle={displayTitle}
          onConfirm={() => {
            onDelete();
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}

      {previewImageIndex !== null && (
        <ImagePopup
          images={product.images}
          currentIndex={previewImageIndex}
          title={displayTitle}
          onClose={() => setPreviewImageIndex(null)}
          onPrevious={() => setPreviewImageIndex(prev => Math.max(0, prev - 1))}
          onNext={() => setPreviewImageIndex(prev => Math.min(product.images.length - 1, prev + 1))}
        />
      )}
    </DragDropImageUpload>
  );
}