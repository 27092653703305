import React from 'react';
import { Minus } from 'lucide-react';
import { PREDEFINED_SHIPPING_METHODS } from '../../utils/shippingConstants';

interface CustomShippingMethod {
  name: string;
  carrierName?: string;
  price: string;
  hokkaido: string;
  okinawa: string;
  island: string;
}

interface CustomShippingMethodProps {
  method: CustomShippingMethod;
  onUpdate: (method: CustomShippingMethod) => void;
  onRemove: () => void;
  showPrices: boolean;
  shippingResponsibility: string;
  shippingLater: boolean;
  shippingCOD: boolean;
}

export const CustomShippingMethod: React.FC<CustomShippingMethodProps> = ({
  method,
  onUpdate,
  onRemove,
  showPrices,
  shippingResponsibility,
  shippingLater,
  shippingCOD
}) => {
  const selectedMethod = PREDEFINED_SHIPPING_METHODS.find(m => m.value === method.name);
  const showRegionalPricing = selectedMethod?.hasRegionalPricing ?? true;
  const isOther = method.name === 'other';

  const handleCarrierNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 15); // Limit to 15 characters
    onUpdate({ ...method, carrierName: value });
  };

  return (
    <div className="border rounded-lg p-4 space-y-3">
      <div className="flex items-center justify-between gap-4">
        <div className="flex-1 space-y-3">
          <select
            value={method.name}
            onChange={(e) => onUpdate({ 
              ...method, 
              name: e.target.value,
              carrierName: e.target.value === 'other' ? '' : undefined 
            })}
            className="w-full rounded-md border-gray-300 bg-gray-50"
          >
            <option value="">配送方法を選択</option>
            {PREDEFINED_SHIPPING_METHODS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {isOther && (
            <div className="flex flex-col space-y-2">
              <label className="text-sm text-gray-600">
                配送業者名 (15文字以内)
              </label>
              <input
                type="text"
                value={method.carrierName || ''}
                onChange={handleCarrierNameChange}
                maxLength={15}
                placeholder="配送業者名を入力"
                className="w-full rounded-md border-gray-300 bg-gray-50"
              />
            </div>
          )}
        </div>
        <button
          onClick={onRemove}
          className="text-red-600 hover:text-red-800 p-1 rounded-full hover:bg-red-50 transition-colors duration-200 flex-shrink-0"
        >
          <Minus className="w-5 h-5" />
        </button>
      </div>

      {!shippingCOD && !shippingLater && showPrices && shippingResponsibility !== 'seller' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
          <div>
            <label className="block text-xs text-gray-600">全国一律</label>
            <input
              type="number"
              value={method.price || ''}
              onChange={(e) => onUpdate({ ...method, price: e.target.value })}
              className="w-full rounded-md border-gray-300 bg-gray-50"
            />
          </div>
          {showRegionalPricing && (
            <>
              <div>
                <label className="block text-xs text-gray-600">北海道</label>
                <input
                  type="number"
                  value={method.hokkaido || ''}
                  onChange={(e) => onUpdate({ ...method, hokkaido: e.target.value })}
                  className="w-full rounded-md border-gray-300 bg-gray-50"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-600">沖縄</label>
                <input
                  type="number"
                  value={method.okinawa || ''}
                  onChange={(e) => onUpdate({ ...method, okinawa: e.target.value })}
                  className="w-full rounded-md border-gray-300 bg-gray-50"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-600">離島</label>
                <input
                  type="number"
                  value={method.island || ''}
                  onChange={(e) => onUpdate({ ...method, island: e.target.value })}
                  className="w-full rounded-md border-gray-300 bg-gray-50"
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};