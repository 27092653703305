import React, { useState } from 'react';
import { Type } from 'lucide-react';
import type { Product } from '../../types';

interface BulkTitleFieldProps {
  onUpdate: (updates: Partial<Product>) => void;
}

export function BulkTitleField({ onUpdate }: BulkTitleFieldProps) {
  const [title, setTitle] = useState('');

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Type className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">商品名を変更</span>
      </div>
      <div className="flex items-center gap-4 flex-1">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="商品名を入力"
          className="flex-1 rounded-md border-gray-300 bg-gray-50 text-sm"
          maxLength={65}
        />
        <button
          onClick={() => {
            if (title.trim()) {
              onUpdate({ title: title.trim() });
              setTitle('');
            }
          }}
          disabled={!title.trim()}
          className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
            title.trim()
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          変更
        </button>
      </div>
    </div>
  );
}