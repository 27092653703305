import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Hash } from 'lucide-react';
import { processTitleWithMemo } from '../utils/titleProcessor';
import { calculateCharacterCount, MAX_TOTAL_LENGTH } from '../utils/characterCount';
import type { Product } from '../types';

interface TitleInputProps {
  value: string;
  onChange: (value: string) => void;
  product: Product;
}

export function TitleInput({ value, onChange, product }: TitleInputProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  // Process title with memo replacement
  const processedTitle = useMemo(() => {
    return processTitleWithMemo(value, product.memo);
  }, [value, product.memo]);

  // Calculate character counts
  const characterCount = useMemo(() => {
    return calculateCharacterCount(product);
  }, [product]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const testProduct = {
      ...product,
      title: newValue
    };
    
    const newCount = calculateCharacterCount(testProduct);
    
    if (newCount.total <= MAX_TOTAL_LENGTH) {
      onChange(newValue);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">商品名</label>
      <div className="mt-2.5 space-y-2">
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-50 h-12 px-3 ${
            !characterCount.isValid ? 'border-red-300' : ''
          }`}
          placeholder="商品名を入力してください"
          spellCheck="false"
          autoComplete="off"
          autoCapitalize="off"
          style={{ whiteSpace: 'pre' }}
        />

        <div className="flex justify-between">
          <div className="flex gap-4 text-sm">
            <span className={characterCount.isValid ? 'text-gray-500' : 'text-red-600 font-medium'}>
              合計: {characterCount.total}/{MAX_TOTAL_LENGTH}
            </span>
            <span className="text-gray-500">
              タイトル: {characterCount.title}
            </span>
            {characterCount.itemNumbers > 0 && (
              <span className="text-gray-500">
                管理番号: {characterCount.itemNumbers}
              </span>
            )}
            {characterCount.memo > 0 && (
              <span className="text-gray-500">
                メモ: {characterCount.memo}
              </span>
            )}
          </div>
          {!characterCount.isValid && (
            <span className="text-sm text-red-600">
              文字数が制限を超えています
            </span>
          )}
        </div>
      </div>
    </div>
  );
}