import React, { useState } from 'react';
import { CATEGORIES } from '../../utils/categories';
import { PREDEFINED_SHIPPING_METHODS } from '../../utils/shippingConstants';
import { ShippingMethodModal } from './ShippingMethodModal';

interface GroupSettingsProps {
  groupIndex: number;
  category: string;
  startPrice: number;
  condition: string;
  shippingMethods?: string[];
  customShippingMethods?: any[];
  onChange: (groupIndex: number, updates: { 
    category?: string; 
    startPrice?: number; 
    condition?: string;
    shippingMethods?: string[];
    customShippingMethods?: any[];
    shippingResponsibility?: string;
    shippingLater?: boolean;
    shippingCOD?: boolean;
    nekoEazySize?: string;
    nekoEazyWeight?: string;
    yuPackSize?: string;
    yuPackWeight?: string;
  }) => void;
}

export function GroupSettings({ 
  groupIndex, 
  category, 
  startPrice, 
  condition,
  shippingMethods = [],
  customShippingMethods = [],
  onChange 
}: GroupSettingsProps) {
  const [showShippingModal, setShowShippingModal] = useState(false);

  const getShippingMethodsDisplay = () => {
    if (shippingMethods.length === 0 && customShippingMethods.length === 0) {
      return 'デフォルト、またはテンプレートを使用';
    }

    const methods = [
      ...shippingMethods.map(method => {
        const predefined = PREDEFINED_SHIPPING_METHODS.find(m => m.value === method);
        return predefined?.label || '';
      }),
      ...customShippingMethods.map(method => 
        method.name === 'other' ? method.carrierName?.slice(0, 15) : ''
      )
    ].filter(Boolean);

    return methods.length > 0 
      ? methods.join('、') 
      : 'デフォルト、またはテンプレートを使用';
  };

  const handleShippingMethodsUpdate = (
    methods: string[], 
    customMethods: any[], 
    options?: {
      shippingResponsibility?: string;
      shippingLater?: boolean;
      shippingCOD?: boolean;
      nekoEazySize?: string;
      nekoEazyWeight?: string;
      yuPackSize?: string;
      yuPackWeight?: string;
    }
  ) => {
    onChange(groupIndex, {
      shippingMethods: methods,
      customShippingMethods: customMethods,
      ...options
    });
  };

  return (
    <div className="mt-4 grid grid-cols-2 gap-4">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">カテゴリ</label>
          <select
            value={category}
            onChange={(e) => onChange(groupIndex, { category: e.target.value })}
            className="w-full rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">デフォルト、またはテンプレートを使用</option>
            {CATEGORIES.map(category => (
              <option key={category.id} value={category.id}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">開始価格</label>
          <input
            type="number"
            value={startPrice || ''}
            onChange={(e) => onChange(groupIndex, { startPrice: Number(e.target.value) })}
            min="0"
            placeholder="デフォルト、またはテンプレートを使用"
            className="w-full rounded-md border-gray-300 bg-gray-50 text-sm"
          />
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">商品の状態</label>
          <select
            value={condition}
            onChange={(e) => onChange(groupIndex, { condition: e.target.value })}
            className="w-full rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">デフォルト、またはテンプレートを使用</option>
            <option value="new">未使用</option>
            <option value="like-new">未使用に近い</option>
            <option value="good">目立った傷や汚れなし</option>
            <option value="fair">やや傷や汚れあり</option>
            <option value="poor">傷や汚れあり</option>
            <option value="bad">全体的に状態が悪い</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">配送方法</label>
          <button
            onClick={() => setShowShippingModal(true)}
            className={`w-full text-center px-4 py-2 rounded-md border text-sm transition-colors ${
              shippingMethods.length > 0 || customShippingMethods.length > 0
                ? 'bg-blue-50 border-blue-200 text-blue-700 hover:bg-blue-100'
                : 'bg-gray-50 border-gray-300 text-gray-600 hover:bg-gray-100'
            }`}
          >
            変更する
          </button>
        </div>
      </div>

      {showShippingModal && (
        <ShippingMethodModal
          selectedMethods={shippingMethods}
          customMethods={customShippingMethods}
          onSelect={handleShippingMethodsUpdate}
          onClose={() => setShowShippingModal(false)}
        />
      )}
    </div>
  );
}