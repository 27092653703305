import React from 'react';
import { Trash2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface UnusedImagesProps {
  images: string[];
  onDelete: (index: number) => void;
}

export function UnusedImages({ images, onDelete }: UnusedImagesProps) {
  if (images.length === 0) return null;

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-base font-medium text-gray-900">
          未使用の写真 ({images.length}枚)
        </h3>
      </div>

      <div className="grid grid-cols-6 gap-4">
        <AnimatePresence>
          {images.map((image, index) => (
            <motion.div
              key={`unused-${index}-${image}`}
              className="relative aspect-square group"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ 
                opacity: 1,
                scale: 1,
                transition: {
                  type: "spring",
                  stiffness: 300,
                  damping: 25,
                  delay: index * 0.05
                }
              }}
              exit={{ opacity: 0, scale: 0.8 }}
            >
              <img
                src={image}
                alt={`未使用画像 ${index + 1}`}
                className="w-full h-full object-cover rounded-lg border-2 border-gray-200"
              />
              <button
                onClick={() => onDelete(index)}
                className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
              >
                <Trash2 className="w-4 h-4" />
              </button>
              <div className="absolute bottom-2 right-2 px-2 py-1 rounded bg-black bg-opacity-75 text-white text-xs">
                {index + 1}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}