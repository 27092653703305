import React, { useState, useCallback } from 'react';
import { Upload } from 'lucide-react';
import { processImageFiles, validateImageCount } from '../utils/imageUtils';
import type { Product } from '../types';

interface DragDropImageUploadProps {
  product: Product;
  onUpdate: (product: Product) => void;
  children: React.ReactNode;
}

export function DragDropImageUpload({ product, onUpdate, children }: DragDropImageUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dragCounter = React.useRef(0);

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;

    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );

    if (files.length === 0) {
      setError('画像ファイルをドロップしてください');
      setTimeout(() => setError(null), 3000);
      return;
    }

    const validation = validateImageCount(product.images.length, files.length);
    if (!validation.valid && validation.error) {
      setError(validation.error);
      setTimeout(() => setError(null), 3000);
      return;
    }

    try {
      // For drag & drop, don't preserve order - sort by filename
      const newImages = await processImageFiles(e.dataTransfer.files, product.images, undefined, false);
      onUpdate({
        ...product,
        images: newImages
      });
    } catch (error) {
      setError('画像の処理中にエラーが発生しました');
      setTimeout(() => setError(null), 3000);
    }
  }, [product, onUpdate]);

  return (
    <div
      className="relative"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}

      {isDragging && (
        <div className="absolute inset-0 bg-blue-50 bg-opacity-90 flex items-center justify-center rounded-lg z-50 border-2 border-blue-500 border-dashed">
          <div className="text-center">
            <Upload className="mx-auto h-12 w-12 text-blue-500 mb-2" />
            <span className="text-lg font-medium text-blue-600">
              ここにドロップ
            </span>
          </div>
        </div>
      )}

      {error && (
        <div className="absolute top-2 right-2 bg-red-50 text-red-600 px-3 py-2 rounded-md text-sm">
          {error}
        </div>
      )}
    </div>
  );
}