import { Product } from '../../../types';
import { PREDEFINED_SHIPPING_METHODS } from '../../shipping/constants';
import { formatShippingTime } from '../../shipping/formats';

export function formatShippingPayer(responsibility: string): string {
  return responsibility === 'seller' ? '出品者' : '落札者';
}

export function formatShippingMethods(product: Product): string[] {
  const fields: string[] = [];

  // Format 10 sets of shipping method fields
  for (let i = 0; i < 10; i++) {
    const method = product.customShippingMethods[i] || {};
    let methodName = '';
    let price = '';
    let hokkaido = '';
    let okinawa = '';
    let island = '';

    if (method.name === 'other') {
      // For custom shipping methods
      methodName = method.carrierName?.slice(0, 15) || '';
      price = method.price?.toString() || '';
      hokkaido = method.hokkaido?.toString() || '';
      okinawa = method.okinawa?.toString() || '';
      island = method.island?.toString() || '';
    } else if (method.name) {
      // For predefined methods
      const predefinedMethod = PREDEFINED_SHIPPING_METHODS.find(m => m.value === method.name);
      if (predefinedMethod) {
        methodName = predefinedMethod.label;
        if (predefinedMethod.hasRegionalPricing) {
          price = method.price?.toString() || '';
          hokkaido = method.hokkaido?.toString() || '';
          okinawa = method.okinawa?.toString() || '';
          island = method.island?.toString() || '';
        } else {
          price = method.price?.toString() || '';
        }
      }
    }

    // Add all fields for this shipping method
    fields.push(
      methodName,  // 配送方法名
      price,       // 全国一律料金
      hokkaido,    // 北海道料金
      okinawa,     // 沖縄料金
      island       // 離島料金
    );
  }

  return fields;
}

export { formatShippingTime };