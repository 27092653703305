import { create } from 'zustand';
import type { Product } from '../types';
import { createTemplateFromProduct } from '../utils/templates/productTemplates';
import type { ProductTemplate } from '../utils/templates/productTemplates';
import { saveTemplate, getTemplates, deleteTemplate } from '../utils/templates/templateStorage';

interface TemplateState {
  templates: ProductTemplate[];
  activeTemplateId: string | null;
  isLoading: boolean;
  loadTemplates: () => Promise<void>;
  addTemplate: (name: string, product: Product) => Promise<void>;
  updateTemplate: (id: string, updates: Partial<ProductTemplate>) => Promise<void>;
  deleteTemplate: (id: string) => Promise<void>;
  setActiveTemplate: (id: string | null) => void;
  getTemplateById: (id: string) => ProductTemplate | undefined;
}

export const useTemplateStore = create<TemplateState>()((set, get) => ({
  templates: [],
  activeTemplateId: null,
  isLoading: false,

  loadTemplates: async () => {
    set({ isLoading: true });
    try {
      const templates = await getTemplates();
      set({ templates, isLoading: false });
    } catch (error) {
      console.error('Failed to load templates:', error);
      set({ isLoading: false });
    }
  },

  addTemplate: async (name, product) => {
    const template = createTemplateFromProduct(product, name);
    try {
      await saveTemplate(template);
      set(state => ({
        templates: [template, ...state.templates],
        activeTemplateId: template.id
      }));
    } catch (error) {
      console.error('Failed to add template:', error);
      throw error;
    }
  },

  updateTemplate: async (id, updates) => {
    const template = get().templates.find(t => t.id === id);
    if (!template) return;

    const updatedTemplate = {
      ...template,
      ...updates,
      updatedAt: new Date().toISOString()
    };

    try {
      await saveTemplate(updatedTemplate);
      set(state => ({
        templates: state.templates.map(t =>
          t.id === id ? updatedTemplate : t
        )
      }));
    } catch (error) {
      console.error('Failed to update template:', error);
      throw error;
    }
  },

  deleteTemplate: async (id) => {
    try {
      await deleteTemplate(id);
      set(state => ({
        templates: state.templates.filter(t => t.id !== id),
        activeTemplateId: state.activeTemplateId === id ? null : state.activeTemplateId
      }));
    } catch (error) {
      console.error('Failed to delete template:', error);
      throw error;
    }
  },

  setActiveTemplate: (id) => {
    set({ activeTemplateId: id });
  },

  getTemplateById: (id) => {
    return get().templates.find(t => t.id === id);
  }
}));